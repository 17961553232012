/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DisplayAmount, DisplayMonetaryAmountPipe } from '@sei/common-components-lib-ux';
import * as Highcharts from 'highcharts';
import { AchievabilityAnalysisResponseAttributes, Balance, ConfidenceLevelData } from '../../../model/proposal';

@Component({
    selector: 'sei-car-monte-carlo-chart',
    templateUrl: './monte-carlo-chart.html'
})
export class MonteCarloChartComponent {
    @Input()
    set monteCarloData(value: AchievabilityAnalysisResponseAttributes) {
        this._monteCarloData = value;
        if (value) {
            setTimeout(() => {
                this.createChart();
            });
        }

    }

    get monteCarloData(): AchievabilityAnalysisResponseAttributes {
        return this._monteCarloData;
    }

    @Input()
    public targetDateYear: number;

    @Input()
    public leftAlignTargetAmount: boolean;

    private _monteCarloData: AchievabilityAnalysisResponseAttributes;
    private chartData: Highcharts.Options;
    private readonly INTERVAL_BETWEEN_YEAR_DATA_POINTS: number = 1;
    private readonly _95TH_PERCENTILE: number = 95;
    private readonly _75TH_PERCENTILE: number = 75;
    private readonly _50TH_PERCENTILE: number = 50;
    private readonly _25TH_PERCENTILE: number = 25;
    private readonly _5TH_PERCENTILE: number = 5;
    private readonly CHART_FONT_SIZE: string = '12px';

    @ViewChild('chartContainer', { static: true })
    private chartContainer: ElementRef;

    constructor(private monetaryAmountPipe: DisplayMonetaryAmountPipe) { }

    private createChart(): void {
        this.createChartData();
        Highcharts.setOptions({
            lang: {
                thousandsSep: ','
            }
        });
        Highcharts.chart(this.chartContainer.nativeElement, this.chartData);
    }

    private mapBalanceListToNumberList(balances: Balance[], interval: number = this.INTERVAL_BETWEEN_YEAR_DATA_POINTS): number[] {
        const balanceList: number[] = balances
            .sort((a: Balance, b: Balance) => Number(a.year) - Number(b.year))
            .filter((_value: Balance, index: number) => index % interval === 0)
            .map((balance: Balance) => Number(balance.amount));
        return balanceList;
    }

    private retrieveConfidenceLevelData(confidenceLevel: number): ConfidenceLevelData {
        return this.monteCarloData.analyticsDetails.confidenceLevelData
            .find((confidenceLevelData: ConfidenceLevelData) => Number(confidenceLevelData.confidenceLevel) === confidenceLevel);
    }

    private formatMonetaryAmount(amount: number): string {
        const displayAmount: DisplayAmount = {
            value: amount,
            actualCurrency: 'USD'
        };
        return this.monetaryAmountPipe.transform(displayAmount);
    }

    private getPlotlineText(): string {
        return `Target ${this.targetDateYear} - ${this.formatMonetaryAmount(Number(this.monteCarloData?.targetData?.at(0)?.amount))}`;
    }

    private createChartData() {
        this.chartData = {
            chart: {
                type: 'line',
                spacingRight: 20
            },
            title: {
                text: '',
                align: 'left'
            },
            tooltip: {
                style: {
                    fontSize: this.CHART_FONT_SIZE
                },
                valuePrefix: '$',
                outside: true
            },
            yAxis: {
                title: {
                    text: 'Monte Carlo Simulation',
                    style: {
                        fontSize: this.CHART_FONT_SIZE,
                        fontWeight: 'bold',
                        color: '#2d2d2d'
                    },
                    margin: 25
                },
                lineWidth: 2,
                gridLineWidth: 2,
                lineColor: '#D9D9D9',
                labels: {
                    formatter() {
                        if (Number(this.value) >= 1000000000) {
                            return '$' + (Number(this.value) / 1000000000) + 'B';
                        } else if (Number(this.value) as number >= 1000000) {
                            return '$' + Number(this.value) / 1000000 + 'M';
                        } else if (Number(this.value) >= 1000) {
                            return '$' + Number(this.value) / 1000 + 'K';
                        } else {
                            return '$' + this.value;
                        }
                    },
                    style: {
                        fontSize: this.CHART_FONT_SIZE
                    }
                }
            },
            xAxis: {
                plotLines: [
                    {
                        color: 'black',
                        dashStyle: 'Dash',
                        value: this.targetDateYear,
                        zIndex: 2,
                        label: {
                            text: this.getPlotlineText(),
                            style: {
                                color: '#000000',
                                fontSize: this.CHART_FONT_SIZE
                            },
                            rotation: 0,
                            x: this.leftAlignTargetAmount ? this.getPlotlineText()?.length * -6 : undefined,
                            y: 20
                        }
                    }
                ],
                lineColor: '#D9D9D9',
                lineWidth: 2,
                tickColor: '#D9D9D9',
                labels: {
                    style: {
                        fontSize: this.CHART_FONT_SIZE
                    }
                },
                allowDecimals: false
            },
            legend: {
                enabled: true,
                itemStyle: {
                    fontSize: this.CHART_FONT_SIZE
                },
                align: 'center'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false,
                        style: {
                            fontSize: this.CHART_FONT_SIZE
                        }
                    },
                    pointStart: new Date(this.monteCarloData?.analyticsDetails?.simulationStartDate)?.getFullYear(),
                    pointInterval: this.INTERVAL_BETWEEN_YEAR_DATA_POINTS
                }
            },
            series: [
                {
                    data: this.mapBalanceListToNumberList(this.retrieveConfidenceLevelData(this._95TH_PERCENTILE)?.endingBalances),
                    label: {
                        enabled: false
                    },
                    marker: {
                        symbol: 'triangle'
                    },
                    color: this.retrieveConfidenceLevelData(this._95TH_PERCENTILE)?.color,
                    type: 'line',
                    name: 'Good Scenarios (95th Percentile)'
                },
                {
                    data: this.mapBalanceListToNumberList(this.retrieveConfidenceLevelData(this._75TH_PERCENTILE)?.endingBalances),
                    label: {
                        enabled: false
                    },
                    marker: {
                        symbol: 'diamond'
                    },
                    color: this.retrieveConfidenceLevelData(this._75TH_PERCENTILE)?.color,
                    type: 'line',
                    name: '75th Percentile'
                },
                {
                    data: this.mapBalanceListToNumberList(this.retrieveConfidenceLevelData(this._50TH_PERCENTILE)?.endingBalances),
                    label: {
                        enabled: false
                    },
                    marker: {
                        symbol: 'circle'
                    },
                    color: this.retrieveConfidenceLevelData(this._50TH_PERCENTILE)?.color,
                    type: 'line',
                    name: 'Median (50th Percentile)'
                },
                {
                    data: this.mapBalanceListToNumberList(this.retrieveConfidenceLevelData(this._25TH_PERCENTILE)?.endingBalances),
                    label: {
                        enabled: false
                    },
                    marker: {
                        symbol: 'square'
                    },
                    color: this.retrieveConfidenceLevelData(this._25TH_PERCENTILE)?.color,
                    type: 'line',
                    name: '25th Percentile'
                },
                {
                    data: this.mapBalanceListToNumberList(this.retrieveConfidenceLevelData(this._5TH_PERCENTILE)?.endingBalances),
                    label: {
                        enabled: false
                    },
                    marker: {
                        symbol: 'triangle-down'
                    },
                    color: this.retrieveConfidenceLevelData(this._5TH_PERCENTILE)?.color,
                    type: 'line',
                    name: 'Poor Scenarios (5th Percentile)'
                }
            ]
        };
    }

}
