<div class="wip-section-footer pull-right">
    <div class="row">
        <div class="col-xs-12 flex-align-center">
            <sei-button class="format-button"
                        *ngIf="!isReadUser"
                        id="saveAndExitBtn"
                        buttonText="Save & Exit"
                        buttonRightFontAwesomeIcon="far fa-save"
                        buttonClass="btn-default"
                        routerLink="/"
                        (buttonClicked)="onSaveExitClick()">
            </sei-button>

            <sei-button class="format-button"
                        *ngIf="isReadUser"
                        id="saveAndExitBtn"
                        buttonText="Exit"
                        buttonRightFontAwesomeIcon="far fa-save"
                        buttonClass="btn-default"
                        routerLink="/"
                        (buttonClicked)="onExitClick()">
            </sei-button>

            <sei-button class="format-button"
                        buttonText="Implement"
                        buttonRightFontAwesomeIcon="fas fa-check"
                        buttonClass="btn-default"
                        [buttonDisabled]="!enableImplementButton || proposal.advisorChangeFlag || isReadUser || !isStatusValid"
                        (buttonClicked)="onImplementToggleClick()"
                        [tooltip]="getStrategiesFundedTooltip()">
            </sei-button>

            <sei-button *ngIf="isChartPOCEnabled"
                        class="format-button"
                        buttonText="Charts POC"
                        buttonRightFontAwesomeIcon="fas fa-arrow-alt-circle-right"
                        buttonClass="btn-default"
                        (buttonClicked)="onChartPOCClick()">
            </sei-button>

            <sei-button *ngIf="isChecklistRedesignEnabled"
                        class="format-button"
                        buttonText="Checklist Redesign"
                        buttonRightFontAwesomeIcon="fas fa-arrow-alt-circle-right"
                        buttonClass="btn-default"
                        (buttonClicked)="onChecklistRedesignClick()"></sei-button>

            <sei-button *ngIf="!isChecklistLocked"
                        id="presentationBtn"
                        buttonText="Presentation"
                        buttonRightFontAwesomeIcon="fas fa-arrow-alt-circle-right"
                        buttonClass="btn-default"
                        [buttonDisabled]="!enablePresentationButton || proposal.advisorChangeFlag || isReadUser || isGoalsIncomplete()"
                        (buttonClicked)="onPresentationClick()"
                        [tooltip]="getStrategiesFundedTooltip()">
            </sei-button>
            <sei-button *ngIf="isChecklistLocked"
                        id="presentationBtn"
                        buttonText="Presentation"
                        buttonRightFontAwesomeIcon="fas fa-arrow-alt-circle-right"
                        buttonClass="btn-default"
                        [buttonDisabled]="!enablePresentationButton || proposal.advisorChangeFlag || isReadUser || isForceRefreshRequiredForCurrentAccounts || isGoalsIncomplete()"
                        [popover]="getLockedTooltip()"
                        [triggers]="'hover'"
                        #pop="bs-popover"
                        (buttonClicked)="onPresentationClick()">
            </sei-button>
        </div>
    </div>
</div>
