/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import moment from 'moment';
import { CurrencyTypes, GoalOptionTypeId, WipAccountStatus } from '../../model/enums';
import { Account, GoalDetail, MdcSnapshot, Proposal, Scenario, WipCheckList } from '../../model/proposal';
import { GoalService } from '../../service/goal.service';


@Component({
    selector: 'sei-car-wip-checklist-goals',
    templateUrl: './wip-checklist-goals.component.html',
    styleUrls: ['./wip-checklist-goals.component.scss']
})
export class WipChecklistGoalsComponent extends ParentComponentSubscriptionManager {

    @Input()
    public wipCheckListGoals: WipCheckList;
    @Input()
    public sectionType: string;
    @Input()
    public proposal: Proposal;

    public showGoalsDetail: boolean = false;
    public currencyTypes: typeof CurrencyTypes = CurrencyTypes;
    public goalStatus: typeof WipAccountStatus = WipAccountStatus;

    private readonly DATE_FORMAT: string = 'YYYY-MM-DD';

    public constructor(private router: Router,
        private goalService: GoalService,
        private featureFlagService: FeatureFlagService
    ) {
        super('WipChecklistGoalsComponent');
    }

    public onShowDetailClick(): void {
        this.showGoalsDetail = !this.showGoalsDetail;
    }

    public navigate() {
        this.router.navigate([`/Proposal/WIP/${this.proposal.id}/Scenarios/${this.proposal.scenarios[0].id}/Goals`]);
    }

    public areGoalsPresent(): boolean {
        return this.wipCheckListGoals?.subCheckList?.length >= 1;
    }

    public enableGoalsFlyout(): boolean {
        return this.proposal?.scenarios?.some((scenario: Scenario) =>
            scenario?.accounts?.some((account: Account) =>
                account?.strategies?.length));
    }

    public getDateLabel(checklist: WipCheckList): string {
        let label: string = 'End Date';
        if (this.featureFlagService.isMonteCarloFeatureEnabled()) {
            const goal: GoalDetail = this.getGoalObjectFromWipChecklist(checklist);
            if (goal?.goalType?.id === GoalOptionTypeId.MajorPurchase ||
                goal?.goalType?.id === GoalOptionTypeId.SecondHome ||
                goal?.goalType?.id === GoalOptionTypeId.WealthTransfer
            ) {
                label = 'Goal Date';
            } else {
                label = 'Start Date - End Date';
            }
        }
        return label;
    }

    public getDateString(checklist: WipCheckList): string {
        let label: string = checklist.mdcSnapShot.goalEndDate || '—';
        if (this.featureFlagService.isMonteCarloFeatureEnabled()) {
            const goal: GoalDetail = this.getGoalObjectFromWipChecklist(checklist);
            if (goal?.goalType?.id !== GoalOptionTypeId.MajorPurchase &&
                goal?.goalType?.id !== GoalOptionTypeId.SecondHome &&
                goal?.goalType?.id !== GoalOptionTypeId.WealthTransfer
            ) {
                if (goal?.startDate &&
                    goal?.endDate) {
                    label = `${this.formatDate(goal.startDate)} - ${this.formatDate(goal.endDate)}`;
                } else {
                    label = '—';
                }
            }
        }
        return label;
    }

    public showFrequency(checklist: WipCheckList): boolean {
        if (this.featureFlagService.isMonteCarloFeatureEnabled()) {
            const goal: GoalDetail = this.getGoalObjectFromWipChecklist(checklist);
            if (goal?.goalType?.id === GoalOptionTypeId.Retirement ||
                goal?.goalType?.id === GoalOptionTypeId.Education
            ) {
                return false;
            }
        }
        return true;
    }

    public getFrequencyValue(checklist: WipCheckList): string {
        let frequency: string = checklist?.mdcSnapShot?.goalFrequency || '—';
        if (this.featureFlagService.isMonteCarloFeatureEnabled()) {
            const goal: GoalDetail = this.getGoalObjectFromWipChecklist(checklist);
            if (goal?.monteCarloAnalytics?.distributionFrequency?.description) {
                frequency = goal.monteCarloAnalytics.distributionFrequency.description;
            } else {
                frequency = '—';
            }
        }
        return frequency;
    }

    public getCurrentAnnualIncome(checklist: WipCheckList): string {
        if (this.featureFlagService.isMonteCarloFeatureEnabled()) {
            const goal: GoalDetail = this.getGoalObjectFromWipChecklist(checklist);
            if (goal?.goalType?.id === GoalOptionTypeId.Retirement) {
                return Number(goal?.monteCarloAnalytics?.currentAnnualIncome).toFixed(2);
            }
        }
        return '0';
    }

    public getPercentOfIncomeNeeded(checklist: WipCheckList): string {
        if (this.featureFlagService.isMonteCarloFeatureEnabled()) {
            const goal: GoalDetail = this.getGoalObjectFromWipChecklist(checklist);
            if (goal?.goalType?.id === GoalOptionTypeId.Retirement) {
                return Number(goal?.monteCarloAnalytics?.percentOfIncomeNeeded).toFixed(2);
            }
        }
        return '0';
    }

    public getAnnualExpenses(checklist: WipCheckList): string {
        if (this.featureFlagService.isMonteCarloFeatureEnabled()) {
            const goal: GoalDetail = this.getGoalObjectFromWipChecklist(checklist);
            if (goal?.goalType?.id === GoalOptionTypeId.Education) {
                return Number(goal?.monteCarloAnalytics?.distributionAmount).toFixed(2);
            }
        }
        return '0';
    }

    public isRetirement(checklist: WipCheckList) {
        if (this.featureFlagService.isMonteCarloFeatureEnabled()) {
            const goal: GoalDetail = this.getGoalObjectFromWipChecklist(checklist);
            return goal?.goalType?.id === GoalOptionTypeId.Retirement;
        }
        return false;
    }

    public isEducation(checklist: WipCheckList) {
        if (this.featureFlagService.isMonteCarloFeatureEnabled()) {
            const goal: GoalDetail = this.getGoalObjectFromWipChecklist(checklist);
            return goal?.goalType?.id === GoalOptionTypeId.Education;
        }
        return false;
    }

    public getAmount(checklist: WipCheckList) {
        let amount: string = checklist?.mdcSnapShot?.goalAmount;
        if (this.featureFlagService.isMonteCarloFeatureEnabled()) {
            const goal: GoalDetail = this.getGoalObjectFromWipChecklist(checklist);
            if (goal?.goalType?.id === GoalOptionTypeId.MajorPurchase ||
                goal?.goalType?.id === GoalOptionTypeId.SecondHome ||
                goal?.goalType?.id === GoalOptionTypeId.WealthTransfer
            ) {
                amount = !isNaN(Number(goal?.monteCarloAnalytics?.targetAmount)) ?
                    Number(goal?.monteCarloAnalytics?.targetAmount).toFixed(2) : undefined;
            } else {
                amount = !isNaN(Number(goal?.monteCarloAnalytics?.distributionAmount)) ?
                    Number(goal?.monteCarloAnalytics?.distributionAmount).toFixed(2) : undefined;
            }
        }
        return amount;

    }

    private formatDate(date: Date): string {
        return moment(date).format(this.DATE_FORMAT);
    }
    public getGoalsSectionStatus(): MdcSnapshot {
        let mdcSnapShot: MdcSnapshot;
        if (this.proposal?.scenarios?.at(0)?.goals?.length > 0) {
            this.proposal.scenarios.at(0).goals.every((goal: GoalDetail) => this.goalService.isThisGoalValid(goal)) ?
                mdcSnapShot = { completed: WipAccountStatus.Complete } :
                mdcSnapShot = { completed: WipAccountStatus.Incomplete };
        }
        return mdcSnapShot;
    }

    getGoalObjectFromWipChecklist(checklist: WipCheckList) {
        const route = checklist.route;
        const lastSlashIndex = route.lastIndexOf('/');
        const id: number = Number(route.substring(lastSlashIndex + 1));
        const goalDetail: GoalDetail = this.proposal?.scenarios?.at(0)?.goals?.find((goal: GoalDetail) => Number(goal.id) === id);
        return goalDetail;
    }

    public getGoalStatusFromWIPChecklistObject(checklist: WipCheckList): boolean {
        const goalDetail: GoalDetail = this.getGoalObjectFromWipChecklist(checklist);
        if (this.proposal?.scenarios?.at(0)?.goals?.length > 0 && goalDetail) {
            return this.goalService.isThisGoalValid(goalDetail);
        }
        return false;
    }

    public getGoalStatus(checklist: WipCheckList): MdcSnapshot {
        return this.getGoalStatusFromWIPChecklistObject(checklist) ?
            {
                completed: WipAccountStatus.Complete
            } :
            {
                completed: WipAccountStatus.Incomplete
            };
    }
}
