/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { TooltipConstants } from '@CarModels/constants';
import { ExistingAccountData, ExistingAccountModelInvestment, ExistingAccountOwner } from '@CarModels/existing-accounts';
import { ExistingAccountsService } from '@CarServices/existing-accounts/existing-accounts.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Account, Client, Proposal, ProposalAccountService } from '@sei/advisor-client-review-proposal-ux';
import { DisplayAmountOptions, DisplayNullOptions, DisplayNumericScaleSuffix, NumericScale } from '@sei/common-components-lib-ux';
import { SubpageheaderModel } from '@sei/common-swp-components-lib-ux';
import { Portfolio, PortfolioHolding } from '@sei/ias-applications-lib-ux';
import _ from 'lodash';
import { ClientFactory } from 'projects/advisor-client-review-proposal-ux/src/lib/model/factory/client-factory';

@Component({
    selector: 'sei-car-existing-accounts-pick-list-item',
    templateUrl: './existing-accounts-pick-list-item.component.html',
    styleUrls: ['./existing-accounts-pick-list-item.component.scss']
})
export class ExistingAccountsPickListItemComponent implements OnInit, OnChanges {
    @Input()
    public existingAccountData: ExistingAccountData;
    @Input()
    public client: Client;
    @Input()
    public proposal: Proposal;
    @Input()
    private selectedExistingAccounts: ExistingAccountData[] = [];
    public isCardCollapsed: boolean = true;
    public isAccountTotalBelowThreshold: boolean = true;
    public selectedClientList: Client[];
    public showModelInvestmentDetails: boolean = true;
    public roundFractionalNumber: boolean = false;
    public existingAccountTypeId: number;
    public proposalClientList: Client[];
    public SHOW_MORE_MESSAGE: string = 'Use the arrow to expand the section details';
    public modelPageHeaderProperties: SubpageheaderModel = { title: 'Model Investments', switcherAvailable: false };
    public showHoverStyling: boolean = false;
    public amountOptions: DisplayAmountOptions = {
        nullDisplay: DisplayNullOptions.ZERO,
        roundFractionalAmount: false
    };
    public displayNumericScaleSuffix: DisplayNumericScaleSuffix = {
        scale: NumericScale.NULL,
        displayDecimals: 2
    };
    public toolTipMessage: string = '';
    private accountBackup: Account;
    constructor(private existingAccountsService: ExistingAccountsService, private proposalAccountService: ProposalAccountService) {

    }

    ngOnInit(): void {
        this.setClientLists();
        this.accountBackup = this.proposal?.scenarios?.at(0)?.accounts
            ?.find((account: Account) => Number(account?.currentAccountId) === Number(this.existingAccountData.accountId));
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.selectedExistingAccounts = changes?.selectedExistingAccounts?.currentValue as ExistingAccountData[];
        this.checkIfMaximumAccountsHasBeenReached();
    }

    public toggleCollapsed($event: Event): void {
        $event.stopPropagation();
        this.isCardCollapsed = !this.isCardCollapsed;
    }

    public toggleShowModelInvestmentDetails($event: Event): void {
        $event.stopPropagation();
        this.showModelInvestmentDetails = !this.showModelInvestmentDetails;
    }

    public toggleSelectionStatusForExistingAccount(): void {
        this.checkIfMaximumAccountsHasBeenReached();
        if (!this.existingAccountData?.isAccountEligibleForSelection ||
            this.isAccountNotEligibleForSelection() ||
            this.existingAccountsService.isPrimaryAdvisorInfoPresentInSF(this.existingAccountData) ||
            this.existingAccountsService.doesAccountContainNonCashAssetWithNegativeBalance(this.existingAccountData)) {
            return;
        }
        const isAccountAlreadyAdded: boolean =
            this.existingAccountsService.isExistingAccountSelectedToBeAddedToProposal(this.existingAccountData);
        if ((isAccountAlreadyAdded && !this.isAccountTotalBelowThreshold) || isAccountAlreadyAdded) {
            this.existingAccountsService.removeExistingAccountFromPickList(this.existingAccountData);
        } else if (this.isAccountTotalBelowThreshold && !this.existingAccountsService.checkIsMaxNumberOfCurrentAccountsSelected()) {
            this.existingAccountsService.addExistingAccountFromPickList(this.existingAccountData, this.accountBackup);
            this.showHoverStyling = false;
        }
    }

    private checkIfMaximumAccountsHasBeenReached(): void {
        this.isAccountTotalBelowThreshold =
            this.proposalAccountService
                .checkIfBelowMaximumNumOfAccounts(this.proposal?.scenarios[0]?.accounts);
    }

    public isExistingAccountSelected(): boolean {
        return this.existingAccountsService.isExistingAccountSelectedToBeAddedToProposal(this.existingAccountData);
    }

    public showCheckmarkOnHover(): void {
        if (!this.isExistingAccountSelected() && !this.isAccountNotEligibleForSelection() &&
            !this.existingAccountsService.isPrimaryAdvisorInfoPresentInSF(this.existingAccountData)) {
            this.showHoverStyling = true;
        }
    }

    public doesAccountContainCorrectedCashAssetWithNegativeBalance(): boolean {
        return this.existingAccountData?.existingNonModelPortfolios?.some((portfolio: Portfolio) =>
            portfolio?.proposedPortfolioHoldingList
                ?.some((asset: PortfolioHolding) => asset?.isNegativeCashAsset)
        ) || this.existingAccountData?.modelInvestments
            ?.some((model: ExistingAccountModelInvestment) => model?.isModelNegativeCash ||
                (model?.doesCurrentAllocationsContainNegativeAsset && model?.isUMAEligibleForSelection));
    }

    public hideCheckmark(): void {
        this.showHoverStyling = false;
    }

    private setClientLists(): void {
        this.selectedClientList = [];
        const primaryAccountOwner: ExistingAccountOwner =
            this.existingAccountData?.accountOwnerList?.find((owner: ExistingAccountOwner) => owner?.primary);
        const primaryOwnerClient: Client = this.proposal.clients
            .find((client: Client) => Number(client?.entityId) === Number(primaryAccountOwner?.endClientId));
        this.existingAccountData?.accountOwnerList.forEach((owner: ExistingAccountOwner) => {
            const ownerClient: Client = new ClientFactory().createAccountOwner(owner.endClientId,
                `${owner.lastName}, ${owner.firstName}`,
                owner.firstName,
                owner.middleName,
                owner.lastName);
            ownerClient.isNewClient = false;
            ownerClient.contactType = primaryOwnerClient.contactType;
            ownerClient.emailAddress = owner.email;
            ownerClient.phoneNumber = owner.phone;
            if (primaryOwnerClient.contactType) {
                this.existingAccountTypeId = primaryOwnerClient.contactType.id;
                if (this.existingAccountTypeId === 2) {
                    ownerClient.organizationName = ownerClient.lastName;
                }
            }
            owner.primary ? this.selectedClientList.unshift(ownerClient) :
                this.selectedClientList = [...this.selectedClientList, ownerClient];
            this.proposalClientList = _.cloneDeep(this.selectedClientList);
        });
    }

    public isAccountNotEligibleForSelection(): boolean {
        const isMarketValueGreaterThanZero: boolean = this.existingAccountData?.modelMarketValue <= 0 &&
            this.existingAccountData?.nonModelMarketValue <= 0;
        return isMarketValueGreaterThanZero;
    }

    private checkAccountContainsEmptyPortfolios(): boolean {
        return this.existingAccountData.existingNonModelPortfolios
            .filter((portfolio: Portfolio) => this.proposalAccountService.calculateValueOfNonModelPortfolio(portfolio) === 0).length > 0;
    }

    public showInfoIcon(): boolean {
        if (this.isAccountNotEligibleForSelection()) {
            this.toolTipMessage = TooltipConstants.ZERO_MARKET_VALUE;
            return true;
        } else if (!this.existingAccountData?.isAccountEligibleForSelection) {
            this.toolTipMessage = TooltipConstants.EXISTING_ACCOUNT_NOT_ELIGIBLE;
            return true;
        } else if (this.existingAccountsService.isPrimaryAdvisorInfoPresentInSF(this.existingAccountData)) {
            this.toolTipMessage = TooltipConstants.EXISTING_ACCOUNT_PRIMARY_ADVISOR;
            return true;
        } else if (this.existingAccountsService.doesAccountContainNonCashAssetWithNegativeBalance(this.existingAccountData)) {
            this.toolTipMessage = TooltipConstants.EXISTING_ACCOUNT_NEGATIVE_NON_CASH;
            return true;
        } else if (this.doesAccountContainCorrectedCashAssetWithNegativeBalance()) {
            this.toolTipMessage = TooltipConstants.EXISTING_ACCOUNT_NEGATIVE_CASH;
            return true;
        } else if (this.checkAccountContainsEmptyPortfolios()) {
            this.toolTipMessage = TooltipConstants.EXISTING_ACCOUNT_EMPTY_PORTFOLIO;
            return true;
        } else {
            return false;
        }
    }

    public showBannedPointer(): boolean {
        this.checkIfMaximumAccountsHasBeenReached();
        return (!this.isAccountTotalBelowThreshold ||
            this.isAccountNotEligibleForSelection() ||
            this.existingAccountsService.isPrimaryAdvisorInfoPresentInSF(this.existingAccountData) ||
            !this.existingAccountData?.isAccountEligibleForSelection
            || this.existingAccountsService.doesAccountContainNonCashAssetWithNegativeBalance(this.existingAccountData)
            || this.existingAccountsService.checkIsMaxNumberOfCurrentAccountsSelected()) && !this.isExistingAccountSelected();
    }

}
