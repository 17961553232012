/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { SpinnerService } from '@CarServices/system/spinner.service';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { CommandText, GoalFundingType, WipAccountStatus, WipChecklistLabels } from '../../model/enums';
import { GoalFactory } from '../../model/factory/goal-factory';
import { Account, Goal, GoalCatalogs, GoalDetail, GoalDetailPayLoad, Proposal, Scenario, Strategy, WipCheckList } from '../../model/proposal';
import { DelegationService } from '../../service/delegation.service';
import { GoalService } from '../../service/goal.service';
import { ProposalService } from '../../service/proposal.service';

@Component({
    selector: 'sei-car-edit-goal',
    templateUrl: './edit-goal.component.html',
    styleUrls: ['./edit-goal.component.scss']
})
export class EditGoalComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public proposal: Proposal;

    public scenario: Scenario;
    public goalCatalogs: GoalCatalogs;
    public goalInitialStatus: GoalDetail[];
    public goalWipChecklists: WipCheckList[];
    public isAddGoalEnabled: boolean = true;
    public isMonteCarloFeatureEnabled: boolean = false;
    private proposalBackup: Proposal;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private goalService: GoalService,
        private proposalService: ProposalService,
        private delegationService: DelegationService,
        private spinnerService: SpinnerService,
        private featureFlagService: FeatureFlagService
    ) {
        super('EditGoalComponent');
    }

    public ngOnInit(): void {
        if (this.proposal.scenarios) {
            const scenarioId = this.route.snapshot.params.scenarioId;
            this.scenario = this.proposal.scenarios.find((scenario) => scenario.id === +scenarioId);
            this.proposalBackup = _.cloneDeep(this.proposal);
            this.initializeComponent();
        }
        this.isMonteCarloFeatureEnabled = this.featureFlagService.isMonteCarloFeatureEnabled();
    }

    public addAdditionalGoal(): void {
        if (!this.scenario.goals) {
            this.scenario.goals = [];
        }
        const goalDetail = new GoalFactory().createGoalDetail(0);
        this.scenario.goals.unshift(goalDetail);
        this.isAddGoalEnabled = false;
        this.setSaveAndExitStatus(true);
        this.updateServiceGoalNames();
    }

    public onRemoveGoal(event: GoalDetail): void {
        const goalDetail = event;
        if (goalDetail) {
            const foundIndexValue =
                this.scenario.goals.indexOf(
                    this.scenario.goals.find(
                        (goal: GoalDetail) => goal === goalDetail)
                );
            if (foundIndexValue !== undefined && this.scenario.goals[foundIndexValue]) {
                this.scenario.goals.splice(foundIndexValue, 1);
            }
        }
        this.updateServiceGoalNames();
        this.isAddGoalEnabled = this.goalService.isThereNotEmptyNames();
        this.setSaveAndExitStatus(
            this.isThereAnyInvalidGoal()
        );
    }

    public onIsFormValid(event: boolean): void {
        const isValidChildGoal: boolean = event;
        let isInvalid: boolean = true;
        if (isValidChildGoal) {
            this.updateGoalValid();
            isInvalid = this.isThereAnyInvalidGoal();
        }

        this.updateServiceGoalNames();
        this.isAddGoalEnabled = this.goalService.isThereNotEmptyNames() && !this.goalService.isNameDuplicated();
        isInvalid = isInvalid || !this.isAddGoalEnabled;
        this.setSaveAndExitStatus(isInvalid);
    }

    private isThereAnyInvalidGoal(): boolean {
        const isValid: boolean =
            !!this.scenario.goals.find((goal: GoalDetail) => goal.isValid === false);
        return isValid;
    }

    private setSaveAndExitStatus(isInvalid: boolean): void {
        const publishEvent: string = isInvalid
            ? CommandText.DisableSaveAndExitButton
            : CommandText.EnableSaveAndExitButton;

        this.delegationService.publish(publishEvent);

    }

    private updateServiceGoalNames(): void {
        this.goalService.setGoalNames(
            this.getGoalBase()
        );
    }

    private getGoalBase(): Goal[] {
        return this.scenario.goals.map((goal: GoalDetail) => goal);
    }

    private initializeComponent(): void {
        this.spinnerService.start();
        const catalogs = forkJoin(
            [this.goalService.getGoalType(),
            this.goalService.getPriority(),
            this.goalService.getFrequency()]
        ).subscribe(
            (results) => {
                if (results) {
                    this.goalCatalogs = {
                        types: this.goalService.assignDataDropDownList(results[0]),
                        priorities: this.goalService.assignDataDropDownList(results[1]),
                        frequencies: this.goalService.assignDataDropDownList(results[2]),
                        distributionFrequency: this.goalService.assignDataDropDownList(results[2])
                    };
                }
            },
            (error) => {
                this.spinnerService.stop();
            },
            () => {
                this.spinnerService.stop();
            }
        );
        this.goalService.getNextGoal(
            this.getGoalBase()
        );

        this.subscriptions.push(catalogs);
        this.goalInitialStatus = _.cloneDeep(this.scenario.goals);
        this.goalWipChecklists = _.cloneDeep(this.proposal?.wipCheckList);
        const subscriptionData = this.delegationService.refresh().subscribe(
            (data) => {
                if (data === CommandText.SaveGoal) {
                    this.saveAndExitGoals();
                }
                if (data === CommandText.CancelGoal) {
                    this.cancelGoals();
                }
            }
        );

        this.subscriptions.push(subscriptionData);

        if (this.scenario.goals && this.scenario.goals.length) {
            this.updateServiceGoalNames();
            this.isAddGoalEnabled = this.goalService.isThereNotEmptyNames();
        }

        this.updateGoalValid();
        this.setSaveAndExitStatus(
            this.isThereAnyInvalidGoal()
        );
    }

    private updateGoalValid(): void {
        this.scenario.goals.forEach((goal: GoalDetail) => {
            goal.isValid = this.goalService.isThisGoalValid(goal);
        });
    }

    private saveAndExitGoals(): void {
        if (this.goalService.isNameDuplicated()) {
            return;
        }
        const proposalId = this.route.snapshot.parent.params.proposalId;
        this.setSaveAndExitStatus(true);
        this.spinnerService.start();
        this.scenario.goals = this.goalService.fixGoalsForSave(this.scenario?.goals);
        this.goalService.saveGoals(this.scenario.goals, proposalId, this.scenario.id)
            .subscribe((goalDetailPayLoad: GoalDetailPayLoad) => {
                if (goalDetailPayLoad) {
                    const goals: GoalDetail[] = goalDetailPayLoad.goalDetail;
                    const wipCheckList: WipCheckList[] = goalDetailPayLoad.wipCheckList;

                    this.scenario.accounts.forEach((account: Account) => {
                        this.goalService.removeDeletedGoals(account, goals);
                    });

                    this.scenario.goals = goals;
                    this.assignGoalsToAccountsAndStrategies(goals);
                    const proposedSectionIndex: number =
                        wipCheckList.findIndex((wipCheckListItem: WipCheckList) =>
                            wipCheckListItem.sectionType === WipChecklistLabels.ProposalScenario
                        );

                    const scenarioGoalIndex: number =
                        wipCheckList[proposedSectionIndex].subCheckList
                            .findIndex((wipCheckListScenario: WipCheckList) =>
                                wipCheckListScenario.name === WipChecklistLabels.ScenarioGoal);

                    if (scenarioGoalIndex !== -1) {
                        if (goals && goals.length > 0) {
                            wipCheckList[proposedSectionIndex]
                                .subCheckList[scenarioGoalIndex].mdcSnapShot.completed = WipAccountStatus.Complete;

                            const routPath: string =
                                wipCheckList[proposedSectionIndex].subCheckList[scenarioGoalIndex].route;

                            const goalsWipCheckList: WipCheckList[] =
                                wipCheckList[proposedSectionIndex].subCheckList[scenarioGoalIndex].subCheckList;

                            wipCheckList[proposedSectionIndex].subCheckList[scenarioGoalIndex].subCheckList = [];


                            goals.forEach((goal: GoalDetail) => {
                                const goalWipCheckList: WipCheckList =
                                    goalsWipCheckList.find((wipCheckListItem: WipCheckList) =>
                                        wipCheckListItem.route === `${routPath}/${goal.id}`
                                    );

                                wipCheckList[proposedSectionIndex].subCheckList[scenarioGoalIndex].subCheckList
                                    .push(this.transformGoalWipCheckList(goalWipCheckList, goal, proposalId));
                            });
                        } else {
                            wipCheckList[proposedSectionIndex].subCheckList.splice(scenarioGoalIndex, 1);
                        }
                        this.proposalService.updateProposalWipCheckList(wipCheckList).subscribe((response: WipCheckList[]) => {
                            if (response) {
                                this.updateProposalWipCheckList(response, proposalId);
                            }
                        });
                    } else {
                        this.updateProposalWipCheckList(wipCheckList, proposalId);
                    }
                }
            },
                (error) => {
                    this.setSaveAndExitStatus(false);
                    this.spinnerService.stop();
                });
    }

    private assignGoalsToAccountsAndStrategies(savedGoals: GoalDetail[]): void {
        if (this.featureFlagService.isMonteCarloFeatureEnabled() && savedGoals?.length) {
            const currentProposal: Proposal = this.proposalService.getCurrentProposal();
            const currentProposedAccounts: Account[] = currentProposal?.scenarios[0]?.accounts;
            const allStrategies: Strategy[] = _.flatten(currentProposedAccounts?.map((account) => account?.strategies));
            savedGoals?.forEach((goal: GoalDetail) => {
                if (!_.isNil(goal?.monteCarloAnalytics)) {
                    if (goal?.monteCarloAnalytics?.accounts?.length) {
                        // current goal has been assigned to accounts
                        currentProposedAccounts?.forEach((account: Account) => {
                            goal.monteCarloAnalytics.accounts.forEach((accountIdAssignedToGoal: number) => {
                                if (account?.id === accountIdAssignedToGoal) {
                                    account.proposalScenarioGoalId = goal?.id;
                                    account.goalFundingTypeId = GoalFundingType.Account;
                                }
                            });
                        });
                    } else if (goal?.monteCarloAnalytics?.strategies?.length) {
                        // current goal has been assigned to strategies
                        allStrategies?.forEach((strategy) => {
                            goal.monteCarloAnalytics.strategies.forEach((strategyIdAssignedToGoal: number) => {
                                if (strategy?.proposalAccountStrategyId === strategyIdAssignedToGoal) {
                                    strategy.proposalScenarioGoalId = goal?.id;
                                    const accountContainingStrategy: Account =
                                        currentProposedAccounts.find((account) => account?.id === strategy?.proposalAccountId);
                                    if (accountContainingStrategy && _.isNil(accountContainingStrategy?.goalFundingTypeId)) {
                                        accountContainingStrategy.goalFundingTypeId = GoalFundingType.Strategy;
                                    }
                                }
                            });
                        });
                    }
                }
            });
            this.proposalService.changedProposal(currentProposal);
        }
    }

    private updateProposalWipCheckList(wipCheckList: WipCheckList[], proposalId: number): void {
        this.proposal.wipCheckList = wipCheckList;
        this.proposalService.changedProposal(this.proposal);
        this.delegationService.publish(CommandText.UpdateWipCheckList);
        this.router.navigate(['../Proposal/WIP/', proposalId]);
        this.spinnerService.stop();
    }

    private cancelGoals(): void {
        this.proposalService.changedProposal(this.proposalBackup);
    }

    private transformGoalWipCheckList(goalWipCheckList: WipCheckList, goal: GoalDetail, routPath: string): WipCheckList {
        goalWipCheckList.name = goal.name;
        goalWipCheckList.mdcSnapShot.goalAmount = goal.endValue ? goal.endValue.toString() : '0';
        goalWipCheckList.mdcSnapShot.goalEndDate = goal.endDate.toISOString().slice(0, 10);
        goalWipCheckList.mdcSnapShot.goalFrequency = goal.frequency.description || '';
        goalWipCheckList.mdcSnapShot.goalPriority = goal.priority.description || '';
        goalWipCheckList.mdcSnapShot.goalType = goal.goalType.description || '';
        goalWipCheckList.mdcSnapShot.completed = WipAccountStatus.Complete;
        return goalWipCheckList;
    }

    public getIndexForGoalName(currentIndex: number): number {
        return Math.abs(currentIndex - this.scenario?.goals?.length);
    }
}
