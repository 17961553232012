/*
Copyright: This information constitutes the exclusive property of SEI
Investments Company, and constitutes the confidential and proprietary
information of SEI Investments Company.  The information shall not be
used or disclosed for any purpose without the written consent of SEI
Investments Company.
*/

import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GoalDropdownLabelHelperText, GoalFrequencyIds, GoalFundingType, GoalOptionTypeId } from '@sei/advisor-client-review-proposal-ux';
import { DropdownItem } from '@sei/common-components-lib-ux';
import { OrderedSet } from 'immutable';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Account, AchievabilityAnalysisRequest, AchievabilityAnalysisResponse, Goal, GoalAccountPair, GoalDetail, GoalDetailPayLoad, GoalFundingTracking, GoalStrategyPair, MonteCarloFormOptions, ProposalScenarioGoalsResponse, Scenario, ScenarioGoal, Strategy, Type } from '../model/proposal';
import { SeiPayload } from '../model/sei-payload';
import { GlobalService } from './system/global.service';

@Injectable({
    providedIn: 'root'
})
export class GoalService {
    private goalNames: BehaviorSubject<Goal[]>;
    private readonly GOAL_NAME_MAX_LENGTH: number = 65;
    private readonly PROPOSAL_SCENARIO_GOALS: string = 'getProposalScenarioGoals';
    private readonly DELIMITER: string = ' ';
    private readonly TYPE_INDEX: number = 0;
    private readonly ID_INDEX: number = 1;
    public goalFundingDropdownChange: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public accountsAndStrategiesAlreadyAssigned: BehaviorSubject<GoalFundingTracking> =
        new BehaviorSubject({ accountsData: [], strategiesData: [] });

    constructor(
        private readonly carGlobal: GlobalService,
        private readonly http: HttpClient,
        private featureFlagService: FeatureFlagService
    ) { }

    public getGoalType():
        Observable<Type[]> {
        // NOTE: This is the code when endpoint is ready
        // const apiUrl = this.carGlobal.configService.routeFormatter(
        //     this.carGlobal.configService.getEndPoint('goalType')
        // );

        // return this.http.get(apiUrl).pipe(
        //     map((response: SeiPayload) => {
        //         if (response) {
        //             return response.data[0];
        //         }
        //     })
        // );

        const returnType: Type[] = [];
        returnType.push({ id: 0, description: 'Select' });
        returnType.push({ id: 1, description: 'Business Investment' });
        returnType.push({ id: 2, description: 'Charitable Gift' });
        returnType.push({ id: 3, description: 'Current Lifestyle' });
        returnType.push({ id: 4, description: 'Distribution' });
        returnType.push({ id: 5, description: 'Education' });
        returnType.push({ id: 6, description: 'Endowment' });
        returnType.push({ id: 7, description: 'Future Lifestyle' });
        returnType.push({ id: 8, description: 'Major Purchase' });
        returnType.push({ id: 9, description: 'Parental Support' });
        returnType.push({ id: 10, description: 'Retirement' });
        returnType.push({ id: 11, description: 'Second Home' });
        returnType.push({ id: 12, description: 'Wealth Accumulation' });
        returnType.push({ id: 13, description: 'Wealth Transfer' });

        return of(returnType);
    }

    public getFrequency():
        Observable<Type[]> {
        // NOTE: This is the code when endpoint is ready
        // const apiUrl = this.carGlobal.configService.routeFormatter(
        //     this.carGlobal.configService.getEndPoint('goalFrequency')
        // );

        // return this.http.get(apiUrl).pipe(
        //     map((response: SeiPayload) => {
        //         if (response) {
        //             return response.data[0];
        //         }
        //     })
        // );

        const returnType: Type[] = [];
        returnType.push({ id: 0, description: 'Select' });
        returnType.push({ id: 1, description: 'Once' });
        returnType.push({ id: 2, description: 'Monthly' });
        returnType.push({ id: 3, description: 'Quarterly' });
        returnType.push({ id: 4, description: 'Semi-Annually' });
        returnType.push({ id: 5, description: 'Annually' });
        return of(returnType);
    }

    public getPriority():
        Observable<Type[]> {
        // NOTE: This is the code when endpoint is ready
        // const apiUrl = this.carGlobal.configService.routeFormatter(
        //     this.carGlobal.configService.getEndPoint('goalPriority')
        // );

        // return this.http.get(apiUrl).pipe(
        //     map((response: SeiPayload) => {
        //         if (response) {
        //             return response.data[0];
        //         }
        //     })
        // );

        const returnType: Type[] = [];
        returnType.push({ id: 0, description: 'Select' });
        returnType.push({ id: 1, description: 'Have to Now' });
        returnType.push({ id: 2, description: 'Want to Now' });
        returnType.push({ id: 3, description: 'Have to Later' });
        returnType.push({ id: 4, description: 'Want to Later' });
        return of(returnType);
    }

    public fixGoalsForSave(goals: GoalDetail[]): GoalDetail[] {
        goals?.forEach((goal: GoalDetail): void => {
            if (goal?.isNewGoal) {
                goal.id = 0;
            }
            if (!goal.frequency || !goal.frequency?.id) {
                goal.frequency = {
                    id: GoalFrequencyIds.NONE
                };
            }
        });
        return goals;
    }

    public saveGoals(goals: GoalDetail[], proposalId: number, scenarioId: number): Observable<GoalDetailPayLoad> {
        const isMonteCarloFeatureEnabled: boolean = this.featureFlagService.isMonteCarloFeatureEnabled();
        const endPoint: string = isMonteCarloFeatureEnabled ? 'updateGoalDetailsMonteCarlo' : 'updateGoalDetails';
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint(endPoint),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                },
                {
                    key: 'scenarioId',
                    value: scenarioId
                }
            ]
        );

        const scenarioGoal: ScenarioGoal = {
            scenarioGoals: goals
        };

        return this.http.put(apiUrl, scenarioGoal).pipe(
            map((response: SeiPayload) => {
                if (response) {
                    const goalDetailPayLoad: GoalDetailPayLoad = {
                        goalDetail: this.transformGoalsJsonDatesToObject(response.data[0]['goals']),
                        wipCheckList: response.data[0]['wipCheckList']
                    };
                    return goalDetailPayLoad;
                }
            })
        );
    }

    public resetGoalNames(): void {
        this.goalNames = new BehaviorSubject<Goal[]>([]);
    }

    public getNextGoal(goals: Goal[]) {
        if (!this.goalNames) {
            this.goalNames = new BehaviorSubject<Goal[]>(
                goals
            );
        }
        return this.goalNames.next(goals);
    }

    public setGoalNames(
        goalName: Goal[]
    ): Goal[] {
        if (goalName) {
            this.goalNames.next(goalName);
        }
        return goalName;
    }

    public getCurrentGoalNames(): Goal[] {
        return this.goalNames.value;
    }

    public isNameDuplicated(): boolean {
        const goalNames: string[] =
            this.goalNames.value.map((goal: Goal) => goal.name);

        if (!goalNames || !goalNames.length) {
            return false;
        }

        const isNameDuplicated: boolean =
            goalNames.every((name: string) => {
                const filterByName: string[] =
                    goalNames.filter((filterName: string) => filterName.toLowerCase() === name.toLowerCase());
                return filterByName && filterByName.length > 1;
            });

        return isNameDuplicated;
    }

    public isThereNotEmptyNames(): boolean {
        return this.goalNames.value.every((goal: GoalDetail) => !!goal.name.trim());
    }

    public transformGoalsJsonDatesToObject(goals: GoalDetail[]): GoalDetail[] {
        if (goals) {
            goals.forEach((goalDetail: GoalDetail) => {
                if (typeof goalDetail.startDate === 'string') {
                    goalDetail.startDate = new Date(this.formatDateTimeZone(goalDetail.startDate));
                }
                if (typeof goalDetail.endDate === 'string') {
                    goalDetail.endDate = new Date(this.formatDateTimeZone(goalDetail.endDate));
                }
            });
        }
        return goals;
    }

    private formatDateTimeZone(stringDate: string): Date {
        const millisecondsFactor: number = 60000;
        const dateToFormat: Date = new Date(stringDate);
        const userTimezoneOffset: number = dateToFormat.getTimezoneOffset() * millisecondsFactor;
        return new Date(dateToFormat.getTime() + userTimezoneOffset);
    }

    public assignDataDropDownList(dataValues: Type[]): OrderedSet<DropdownItem> {
        const dropdownList: DropdownItem[] = [];
        dataValues.forEach((data: Type) => {
            dropdownList.push(new DropdownItem(data.id, data.description));
        });
        return OrderedSet<DropdownItem>(dropdownList);
    }

    public isThisGoalValid(goal: GoalDetail): boolean {

        if (this.featureFlagService.isMonteCarloFeatureEnabled()) {
            const existingGoalFieldsValid: boolean =
                goal.name.length > 0 &&
                goal.name.length <= this.GOAL_NAME_MAX_LENGTH &&
                goal.goalType.id > 0 &&
                goal.priority.id > 0 &&
                goal.startDate &&
                goal.endDate &&
                !!goal.name.trim();
            const contributionFieldsValid: boolean = (goal?.endValue > 0) ? !!goal?.frequency?.id : true;
            return existingGoalFieldsValid && contributionFieldsValid && this.areAllMonteCarloFieldsValid(goal);
        }
        const isBasicFormValid: boolean =
            goal.name.length > 0 &&
            goal.name.length <= this.GOAL_NAME_MAX_LENGTH &&
            goal.goalType.id > 0 &&
            goal.priority.id > 0 &&
            goal.frequency.id > 0 &&
            goal.startDate &&
            goal.endDate &&
            goal.startDate < goal.endDate &&
            goal.endValue > 0 &&
            !!goal.name.trim();
        return isBasicFormValid;
    }

    public removeDeletedGoals(account: Account, goals: GoalDetail[]) {
        if (account?.proposalScenarioGoalId && !goals.some((goal: GoalDetail) => goal?.id === account?.proposalScenarioGoalId)) {
            account.proposalScenarioGoalId = undefined;
        }
        account?.strategies?.forEach((strategy: Strategy) => {
            if (strategy.proposalScenarioGoalId && !goals.some((goal: GoalDetail) => goal.id === strategy.proposalScenarioGoalId)) {
                strategy.proposalScenarioGoalId = undefined;
            }
        });
    }

    public retrieveMonteCarloAnalysis(request: AchievabilityAnalysisRequest): Observable<AchievabilityAnalysisResponse> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('monteCarloAnalysis')
        );
        return this.http.post<AchievabilityAnalysisResponse>(apiUrl, request);
    }

    public retrieveMonteCarloFormOptionsForGoalTypeId(goalTypeId: number): MonteCarloFormOptions {
        let formOptions: MonteCarloFormOptions;
        switch (goalTypeId) {
            case GoalOptionTypeId.BusinessInvestments:
            case GoalOptionTypeId.CharitableGifts:
            case GoalOptionTypeId.CurrentLifestyle:
            case GoalOptionTypeId.DistributionFocusedStrategies:
            case GoalOptionTypeId.Endowment:
            case GoalOptionTypeId.FutureLifestyle:
            case GoalOptionTypeId.ParentalSupport:
            case GoalOptionTypeId.WealthAccumulation:
                formOptions = {
                    distributionAmount: {
                        show: true,
                        disable: false
                    },
                    distributionFrequency: {
                        show: true,
                        disable: false
                    },
                    targetAmount: {
                        show: false,
                        disable: false
                    }
                };
                break;
            case GoalOptionTypeId.Education:
                formOptions = {
                    distributionAmount: {
                        show: false,
                        disable: false
                    },
                    distributionFrequency: {
                        show: false,
                        disable: false
                    },
                    targetAmount: {
                        show: false,
                        disable: false
                    }
                };
                break;
            case GoalOptionTypeId.Retirement:
                formOptions = {
                    distributionAmount: {
                        show: false,
                        disable: false
                    },
                    distributionFrequency: {
                        show: false,
                        disable: false
                    },
                    targetAmount: {
                        show: false,
                        disable: false
                    }
                };
                break;
            case GoalOptionTypeId.MajorPurchase:
            case GoalOptionTypeId.SecondHome:
            case GoalOptionTypeId.WealthTransfer: {
                formOptions = {
                    distributionAmount: {
                        show: false,
                        disable: true,
                        defaultValue: 0
                    },
                    distributionFrequency: {
                        show: false,
                        disable: true,
                        defaultValue: GoalFrequencyIds.ONCE
                    },
                    targetAmount: {
                        show: true,
                        disable: false
                    }
                };
                break;
            }
        }
        return formOptions;
    }

    private areAllMonteCarloFieldsValid(goal: GoalDetail): boolean {
        const monteCarloFormOptions: MonteCarloFormOptions = this.retrieveMonteCarloFormOptionsForGoalTypeId(goal?.goalType?.id);
        const areGoalTypeNonSpecificFieldsValid: boolean =
            (goal?.monteCarloAnalytics?.accounts?.length > 0 || goal?.monteCarloAnalytics?.strategies?.length > 0) &&
            Number(goal?.monteCarloAnalytics?.initialContribution) > 0;
        let areTypeSpecificFieldsValid: boolean;
        if (goal.goalType.id === GoalOptionTypeId.Retirement) {
            areTypeSpecificFieldsValid =
                Number(goal?.monteCarloAnalytics?.currentAnnualIncome) > 0 &&
                Number(goal?.monteCarloAnalytics?.percentOfIncomeNeeded) > 0;
        } else if (goal.goalType.id === GoalOptionTypeId.Education) {
            areTypeSpecificFieldsValid =
                Number(goal?.monteCarloAnalytics?.distributionAmount) > 0;
        } else if (goal.goalType.id === GoalOptionTypeId.MajorPurchase ||
            goal.goalType.id === GoalOptionTypeId.SecondHome ||
            goal.goalType.id === GoalOptionTypeId.WealthTransfer) {
            areTypeSpecificFieldsValid =
                Number(goal?.monteCarloAnalytics?.targetAmount) > 0;
        } else {
            areTypeSpecificFieldsValid =
                Number(goal?.monteCarloAnalytics?.distributionAmount) > 0 &&
                Number(goal?.monteCarloAnalytics?.distributionFrequency?.id) > 0;
        }
        let isTargetAmountValid: boolean = true;
        if (monteCarloFormOptions?.targetAmount?.show) {
            isTargetAmountValid = goal?.monteCarloAnalytics?.targetAmount > 0;
        }
        return areGoalTypeNonSpecificFieldsValid && areTypeSpecificFieldsValid && isTargetAmountValid;
    }

    public getProposalScenarioGoalsAndUpdateProposal(proposalId: number, scenarioId: number): Observable<ProposalScenarioGoalsResponse> {
        if (proposalId && scenarioId) {
            const apiUrl = this.carGlobal.configService.routeFormatter(
                this.carGlobal.configService.getEndPoint(this.PROPOSAL_SCENARIO_GOALS),
                [
                    {
                        key: 'proposalId',
                        value: proposalId
                    },
                    {
                        key: 'scenarioId',
                        value: scenarioId
                    }
                ]
            );
            return this.http.get<ProposalScenarioGoalsResponse>(apiUrl);
        }
    }

    public setUpGoalFundingTrackingData(allGoals: GoalDetail[]): void {
        if (allGoals?.length) {
            const goalTrackingData: GoalFundingTracking =
                this.accountsAndStrategiesAlreadyAssigned.value;
            allGoals.forEach((goal: GoalDetail): void => {
                const accountIds: number[] = goal?.monteCarloAnalytics?.accounts;
                const strategyIds: number[] = goal?.monteCarloAnalytics?.strategies;
                if (accountIds?.length) {
                    accountIds.forEach((accountId: number) => {
                        goalTrackingData.accountsData.push({ accountId, goalId: goal.id });
                    });
                }
                if (strategyIds?.length) {
                    strategyIds.forEach((strategyId: number) => {
                        goalTrackingData.strategiesData.push({ strategyId, goalId: goal.id });
                    });
                }
            });
            this.accountsAndStrategiesAlreadyAssigned.next(goalTrackingData);
        }
    }

    addOrRemoveAccountOrStrategyFromFundingStatus(addItem: boolean,
        elementToAddOrRemove: GoalDropdownLabelHelperText, accountOrStrategyId: number, goalId: number,
        parentId: number = undefined): void {
        const currentAccountsAndStrategiesFunding: GoalFundingTracking =
            this.accountsAndStrategiesAlreadyAssigned.value;
        if (addItem && elementToAddOrRemove === GoalDropdownLabelHelperText.ENTIRE_ACCOUNT) {
            currentAccountsAndStrategiesFunding.accountsData.push({ accountId: accountOrStrategyId, goalId });
        } else if (addItem && elementToAddOrRemove === GoalDropdownLabelHelperText.INDIVIDUAL_STRATEGY) {
            currentAccountsAndStrategiesFunding.strategiesData.push({ strategyId: accountOrStrategyId, goalId });
        } else if (!addItem && elementToAddOrRemove === GoalDropdownLabelHelperText.ENTIRE_ACCOUNT) {
            currentAccountsAndStrategiesFunding.accountsData =
                currentAccountsAndStrategiesFunding.accountsData.filter((accountPair) => accountPair.accountId !== accountOrStrategyId);
        } else if (!addItem && elementToAddOrRemove === GoalDropdownLabelHelperText.INDIVIDUAL_STRATEGY) {
            currentAccountsAndStrategiesFunding.strategiesData =
                currentAccountsAndStrategiesFunding.strategiesData
                    .filter((strategyPair) => strategyPair.strategyId !== accountOrStrategyId);
            currentAccountsAndStrategiesFunding.accountsData =
                currentAccountsAndStrategiesFunding.accountsData.filter((accountPair) => accountPair?.accountId !== parentId);
        }
        this.accountsAndStrategiesAlreadyAssigned.next(currentAccountsAndStrategiesFunding);
    }

    public resetGoalAssignmentDataForDropdownsForGivenGoal(goalId: number): void {
        const accountAndStrategiesSelections: GoalFundingTracking =
            this.accountsAndStrategiesAlreadyAssigned.value;
        const accountSelections: GoalAccountPair[] = accountAndStrategiesSelections.accountsData;
        const strategiesSelections: GoalStrategyPair[] = accountAndStrategiesSelections.strategiesData;
        accountAndStrategiesSelections.accountsData =
            accountSelections.filter((accountPair: GoalAccountPair) => accountPair?.goalId !== goalId);
        accountAndStrategiesSelections.strategiesData =
            strategiesSelections.filter((strategyPair: GoalStrategyPair) => strategyPair?.goalId !== goalId);
        this.accountsAndStrategiesAlreadyAssigned.next(accountAndStrategiesSelections);
    }

    public updateDropdownItemsSelectionEligibilityStatus(dropdownItems: DropdownItem[], goalId: number,
        allScenarioAccounts: Account[]): void {
        const strategiesAlreadyAssigned: number[] = [];
        dropdownItems.forEach((dropdownItem: DropdownItem) => {
            const elementType: string = this.getDropdownType(dropdownItem);
            const elementId: number = this.getIdFromDropdown(dropdownItem);
            const currentFundingData: GoalFundingTracking = this.accountsAndStrategiesAlreadyAssigned.value;
            if (elementType === GoalDropdownLabelHelperText.INDIVIDUAL_STRATEGY) {
                const isStrategyOptionDisabled: boolean = currentFundingData.strategiesData
                    .some((strategyPair) => (strategyPair?.strategyId === elementId) && (strategyPair?.goalId !== goalId)) ||
                    strategiesAlreadyAssigned.includes(elementId);
                dropdownItem.disabled = isStrategyOptionDisabled;
            } else if (elementType === GoalDropdownLabelHelperText.ENTIRE_ACCOUNT) {
                dropdownItem.disabled =
                    this.areSomeStrategiesSelectedIndividuallyFromAnyOtherGoal(elementId, allScenarioAccounts, goalId) ||
                    this.isEntireAccountOptionAlreadySelectedFromAnyOtherGoal(elementId, goalId);
                const isEntireAccountOptionSelected: boolean =
                    currentFundingData.accountsData.some((accountPair) => accountPair?.accountId === elementId);
                if (isEntireAccountOptionSelected) {
                    const allAssociatedStrategies: DropdownItem[] = dropdownItems.filter((item) => item?.parentId === elementId);
                    allAssociatedStrategies?.forEach((strategyEntry: DropdownItem) => {
                        const strategyId: number = this.getIdFromDropdown(strategyEntry);
                        strategiesAlreadyAssigned.push(strategyId);
                    });
                }
            }
        });
    }

    private isEntireAccountOptionAlreadySelectedFromAnyOtherGoal(accountId: number, goalId: number): boolean {
        const allSelectedAccounts: GoalAccountPair[] = this.accountsAndStrategiesAlreadyAssigned.value.accountsData;
        let isAccountAlreadySelected: boolean = false;
        allSelectedAccounts?.forEach((accountPair: GoalAccountPair) => {
            if (accountPair?.accountId === accountId && accountPair?.goalId !== goalId) {
                isAccountAlreadySelected = true;
            }
        });
        return isAccountAlreadySelected;
    }

    private areSomeStrategiesSelectedIndividuallyFromAnyOtherGoal(accountId: number, allScenarioAccounts: Account[],
        goalId: number): boolean {
        let isOtherStrategyFromAccountSelectedInAnotherGoal: boolean = false;
        const allSelectedStrategiesFromOtherGoals: number[] =
            this.accountsAndStrategiesAlreadyAssigned.value.strategiesData
                .filter((strategyPair) => strategyPair?.goalId !== goalId)
                .map((strategyPair) => strategyPair?.strategyId);
        allScenarioAccounts?.forEach((account: Account) => {
            account?.strategies?.forEach((strategy: Strategy) => {
                if (account?.id === accountId && allSelectedStrategiesFromOtherGoals.includes(strategy?.proposalAccountStrategyId)) {
                    isOtherStrategyFromAccountSelectedInAnotherGoal = true;
                }
            });
        });
        return isOtherStrategyFromAccountSelectedInAnotherGoal;
    }

    public isAnyOtherStrategyFromAccountSelected(proposalAccountStrategyId: number, scenario: Scenario): boolean {
        const matchingAccount: Account = scenario?.accounts
            ?.find((account) => account?.strategies
                ?.find((strategy) => strategy?.proposalAccountStrategyId === proposalAccountStrategyId));
        if (matchingAccount) {
            const selectedStrategies: GoalStrategyPair[] = this.accountsAndStrategiesAlreadyAssigned.value.strategiesData;
            const strategyIdsFromAccount: number[] = matchingAccount.strategies.map((strategy) => strategy?.proposalAccountStrategyId);
            return selectedStrategies?.some((selectedStrategy) => strategyIdsFromAccount?.includes(selectedStrategy?.strategyId));
        }
        return false;
    }

    public removeAssociatedAccountAndStrategySelectionsForGoal(goalId: number): void {
        const updatedAccountSelections: GoalAccountPair[] =
            this.accountsAndStrategiesAlreadyAssigned.value.accountsData.filter((accountPair) => accountPair?.goalId !== goalId);
        const updatedStrategySelections: GoalStrategyPair[] =
            this.accountsAndStrategiesAlreadyAssigned.value.strategiesData.filter((strategyPair) => strategyPair?.goalId !== goalId);
        const updatedAccountStrategyGoalsData: GoalFundingTracking = {
            accountsData: updatedAccountSelections,
            strategiesData: updatedStrategySelections
        };
        this.accountsAndStrategiesAlreadyAssigned.next(updatedAccountStrategyGoalsData);
    }

    public setGoalFundingInfoOnProposalAccount(proposalAccount: Account, goals: GoalDetail[]): Account {
        let isAccountFundingAGoal: boolean = false;
        let proposalScenarioGoalId: number;
        goals?.forEach((goal: GoalDetail): void => {
            if (goal?.monteCarloAnalytics?.accounts?.includes(proposalAccount?.id)) {
                isAccountFundingAGoal = true;
                proposalScenarioGoalId = goal?.id;
            }
        });

        if (!isAccountFundingAGoal) {
            proposalAccount?.strategies?.forEach((strategy: Strategy): void => {
                goals?.forEach((goal: GoalDetail): void => {
                    if (goal?.monteCarloAnalytics?.strategies?.includes(strategy?.proposalAccountStrategyId)) {
                        strategy.proposalScenarioGoalId = goal?.id;
                    }
                });
            });
        }
        proposalAccount.proposalScenarioGoalId = proposalScenarioGoalId;
        proposalAccount.goalFundingTypeId = isAccountFundingAGoal ? GoalFundingType.Account : GoalFundingType.Strategy;
        return proposalAccount;
    }

    public updateGoalsAfterAccountSave(proposalAccount: Account, goalsToUpdate: GoalDetail[]): GoalDetail[] {
        if (proposalAccount && goalsToUpdate?.length) {
            const accountStrategyIds: number[] = proposalAccount?.strategies?.map((strategy) => strategy?.proposalAccountStrategyId);
            goalsToUpdate?.forEach((goalDetail: GoalDetail): void => {
                if (goalDetail?.monteCarloAnalytics) {
                    goalDetail.monteCarloAnalytics.accounts =
                            goalDetail.monteCarloAnalytics.accounts?.filter((accountId) => accountId !== proposalAccount?.id);
                    goalDetail.monteCarloAnalytics.strategies =
                            goalDetail.monteCarloAnalytics.strategies?.filter((strategyId) => !accountStrategyIds?.includes(strategyId));
                    if (proposalAccount?.goalFundingTypeId === GoalFundingType.Account &&
                            proposalAccount?.proposalScenarioGoalId === goalDetail?.id) {
                        if (!goalDetail.monteCarloAnalytics.accounts) {
                            goalDetail.monteCarloAnalytics.accounts = [];
                        }
                        goalDetail.monteCarloAnalytics.accounts?.push(proposalAccount?.id);
                    }
                    if (proposalAccount?.goalFundingTypeId === GoalFundingType.Strategy) {
                        proposalAccount?.strategies?.forEach((strategy: Strategy): void => {
                            if (strategy?.proposalScenarioGoalId === goalDetail?.id) {
                                if (!goalDetail.monteCarloAnalytics.strategies) {
                                    goalDetail.monteCarloAnalytics.strategies = [];
                                }
                                goalDetail.monteCarloAnalytics.strategies?.push(strategy?.proposalAccountStrategyId);
                            }
                        });
                    }
                }
            });
            return goalsToUpdate;
        }
        return [];
    }

    public resetAccountsAndStrategiesSelections(): void {
        this.accountsAndStrategiesAlreadyAssigned.next({ accountsData: [], strategiesData: [] });
    }

    public getDropdownType(dropdownItem: DropdownItem): string {
        return dropdownItem?.id?.toString()?.split(this.DELIMITER)[this.TYPE_INDEX];
    }

    public getIdFromDropdown(dropdownItem: DropdownItem): number {
        return Number(dropdownItem?.id?.toString()?.split(this.DELIMITER)[this.ID_INDEX]);
    }
}
