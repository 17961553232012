/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { GenericErrorService } from '@CarServices/system/generic-error.service';
import { SpinnerService } from '@CarServices/system/spinner.service';
import { Proposal, ProposalScenarioGoalsResponse, ProposalService } from '@sei/advisor-client-review-proposal-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { GoalService } from 'projects/advisor-client-review-proposal-ux/src/lib/service/goal.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sei-car-edit-goal-container',
    templateUrl: './edit-goal-container.component.html'
})
export class EditGoalContainerComponent extends ParentComponentSubscriptionManager implements OnDestroy, OnInit {

    public proposalId: number;
    public proposal: Proposal;
    public goalsRefreshComplete: boolean = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private featureFlagService: FeatureFlagService,
        private genericErrorService: GenericErrorService,
        private goalService: GoalService,
        private proposalService: ProposalService,
        private spinnerService: SpinnerService
    ) {
        super('EditGoalContainerComponent');
    }

    public ngOnInit(): void {
        this.proposalId = Number(this.activatedRoute.parent.snapshot.paramMap.get('proposalId'));
        this.proposal = this.proposalService.getCurrentProposal();
        if (this.featureFlagService.isMonteCarloFeatureEnabled()) {
            const scenarioId: number = this.proposal?.scenarios[0]?.id;
            this.spinnerService.start();
            const goalsSubsription: Subscription =
                this.goalService.getProposalScenarioGoalsAndUpdateProposal(this.proposalId, scenarioId)
                    .subscribe({
                        next: (proposalScenarioGoalsResponse: ProposalScenarioGoalsResponse) => {
                            if (proposalScenarioGoalsResponse) {
                                this.proposal.scenarios[0].goals = proposalScenarioGoalsResponse.data[0].reverse();
                                this.goalService.setUpGoalFundingTrackingData(this.proposal.scenarios[0].goals);
                                this.proposalService.changedProposal(this.proposal);
                            }
                        },
                        error: (error) => {
                            this.genericErrorService.setGenericError({ code: '500', description: error });
                            this.spinnerService.stop();
                        },
                        complete: () => {
                            this.spinnerService.stop();
                            this.goalsRefreshComplete = true;
                        }
                    });
            this.subscriptions.push(goalsSubsription);
        } else {
            this.goalsRefreshComplete = true;
        }
    }

    public ngOnDestroy(): void {
        this.goalService.resetAccountsAndStrategiesSelections();
        super.ngOnDestroy();
    }
}
