/*
 * Copyright: This information co../../../model/presentationty of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DisplayNullOptions, EditAmountOptions } from '@sei/common-components-lib-ux';
import * as _ from 'lodash';
import { CurrencyTypes, ProposalSectionLabel, ProposalStatementSectionIds } from 'projects/advisor-client-review-proposal-ux/src/lib/model/enums';
import { PresentationChecklistService } from 'projects/advisor-client-review-proposal-ux/src/lib/service/presentation-checklist.service';
import { UtilityService } from 'projects/advisor-client-review-proposal-ux/src/lib/service/system/utility.service';
import { Scenario } from '@sei/advisor-client-review-proposal-ux';
import { SettingsElementComponent } from '../../../../common/settings-element/settings-element.component';
import { AnalyticsBenchmark, ProposalSection, SelectOption } from '../../../../model/presentation';
import { DataSharingService } from '../../../../service/data-sharing.service';
import { PresentationSettingService } from '../../../../service/presentation-setting.service';
import { ProposalService } from '../../../../service/proposal.service';

@Component({
    selector: 'sei-car-analytics',
    templateUrl: './analytics.component.html'
})
export class AnalyticsComponent extends SettingsElementComponent implements OnInit {

    @Input()
    public scenario: Scenario;

    private defaultDropdownItems: SelectOption[] = [];
    private benchmarkDropdownItems: SelectOption[] = [];
    public targetDate: SelectOption[] = [
        { label: '5 Yrs', value: '5' },
        { label: '10 Yrs', value: '10' },
        { label: '15 Yrs', value: '15' },
        { label: '20 Yrs', value: '20' },
        { label: '30 Yrs', value: '30' },
        { label: '50 Yrs', value: '50' }
    ];
    public amountOptions: EditAmountOptions;
    public currencyTypes: typeof CurrencyTypes = CurrencyTypes;
    public doesScenarioHasGoals: boolean = false;
    public scenarioAmount: number = 0;
    public targetAmountDefaultValue = '-1';
    public achievabilityAnalysisSectionId = ProposalStatementSectionIds.AchievabilityAnalysisSectionId;
    public targetAmountSectionId = ProposalStatementSectionIds.TargetAmountSectionId;
    public inflationRateSectionId = ProposalStatementSectionIds.InflationRateSectionId;

    constructor(
        presentationSettingService: PresentationSettingService,
        dataSharingService: DataSharingService,
        route: ActivatedRoute,
        router: Router,
        proposalService: ProposalService,
        private readonly utilityService: UtilityService,
        private readonly presentationChecklistService: PresentationChecklistService) {
        super(presentationSettingService, dataSharingService, router, route, proposalService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.amountOptions = {
            minValueAllowed: '0',
            nullDisplay: DisplayNullOptions.ZERO
        };
        this.doesScenarioHasGoals = this.proposalService.doesScenarioContainAnyGoals();
        this.scenarioAmount = this.scenario?.amount;
        const achievabilityAnalysisSection: ProposalSection = this.proposalSection.sections
            .find((section: ProposalSection) => section.statementSectionId === this.achievabilityAnalysisSectionId);
        if (achievabilityAnalysisSection) {
            const targetAmountSection: ProposalSection = achievabilityAnalysisSection.sections
                .find((section: ProposalSection) => section.statementSectionId === this.targetAmountSectionId);
            if (targetAmountSection && targetAmountSection.inputProperty.value === this.targetAmountDefaultValue) {
                targetAmountSection.inputProperty.value = this.utilityService.currencyFormat(this.scenarioAmount);
            }
        }
        this.presentationChecklistService.retrieveAnalyticsBenchmarkItems().subscribe((benchmarkItems: AnalyticsBenchmark[]) => {
            benchmarkItems.forEach((item: AnalyticsBenchmark) =>
                this.benchmarkDropdownItems.push({ value: item.analyticsBenchmarkName, label: item.analyticsBenchmarkName }));
        });
    }


    public parentToggleChange(): void {
        this.dataSharingService.proposalSectionChanged.next(this.proposalSection);
    }

    public modelChanged(subSection: ProposalSection): void {
        this.dataSharingService.proposalSectionChanged.next(this.proposalSection);
    }

    public dropItem(event: CdkDragDrop<ProposalSection[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }

        event.container.data.forEach((section, index) => section.sortOrder = index);

        if (this.proposalSection) {
            this.proposalSection.sections = _.cloneDeep(event.container.data);
            this.dataSharingService.proposalSectionChanged.next(this.proposalSection);
        }

    }

    public getConnectedList(): String[] {
        return this.proposalSection.sections.map((x) => `${x.id}`);
    }

    public onSectionValueChange(subSection: ProposalSection): void {
        if (subSection.isActive) {
            this.dataSharingService.proposalSectionChanged.next(this.proposalSection);
        }
    }

    public getDropDownSource(section: ProposalSection): SelectOption[] {
        switch (section.name) {
            case ProposalSectionLabel.MarketIndex:
                return this.benchmarkDropdownItems;
            case ProposalSectionLabel.TimeHorizon:
                return this.targetDate;
            default:
                return this.defaultDropdownItems;
        }
    }
}
