<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12">
            <car-generic-error-notification></car-generic-error-notification>
        </div>
    </div>
</div>
<sei-loading-overlay [show]="isLoading"
                     [style]="styleInput">
</sei-loading-overlay>
<ng-container *ngIf="pdfCheckListStream | async as pdfCheckList">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <h3>{{pdfCheckList.checklist.proposalName}}</h3>
                <hr>
            </div>
        </div>
    </div>
    <div id="main-content"
         class="container-fluid">
        <div class="row">
            <div class="col-xl-6 col-lg-7 col-md-8 col-sm-9">
                <sei-car-presentation-checklist *ngFor="let proposalStatement of pdfCheckList.checklist.proposalStatement; let i=index;"
                                                [proposalStatement]="proposalStatement"
                                                [scenario]="scenarioDataDefault"
                                                (savePdfStatement)="onSavePdfStatement($event, pdfCheckList.checklist)"
                                                (saveAdvisorContent)="onSaveAdvisorContent(pdfCheckList.checklist, $event)"
                                                (brandLogoUpload)="onBrandLogoUpload($event, pdfCheckList.checklist)"
                                                (onDeleteSection)="onDeleteSection($event, pdfCheckList.checklist, proposalStatement, index)"></sei-car-presentation-checklist>
                <div class="row no-row-padding button-gap">
                    <div class="col-xs-12 text-right">
                        <div class="selections-default-padding">
                            <sei-button buttonText="Make Selections My Default"
                                        buttonLeftFontAwesomeIcon="fas fa-check"
                                        buttonClass="btn-default"
                                        [buttonDisabled]="!isSaveDefaultActive || isReadUser"
                                        [buttonActive]="!isSaveDefaultActive"
                                        (buttonClicked)="saveSelectionsAsDefault(pdfCheckList.checklist)">
                            </sei-button>
                        </div>
                        <hr>
                    </div>
                </div>
                <div class="row no-row-padding button-gap">
                    <div class="col-xs-4">
                        <sei-button buttonText="Back"
                                    buttonLeftFontAwesomeIcon="fas fa-arrow-circle-left"
                                    buttonClass="btn-default"
                                    [routerLink]="['/Proposal/Presentation/Settings/', proposalId]">
                        </sei-button>
                    </div>
                    <div class="col-xs-8 text-right">
                        <sei-button buttonText="Save & Exit"
                                    buttonRightFontAwesomeIcon="fas fa-save"
                                    buttonClass="btn-default"
                                    [buttonDisabled]="isReadUser || isLoading"
                                    (buttonClicked)="onSaveCheckList(pdfCheckList.checklist)">
                        </sei-button>&nbsp;
                        <sei-button *ngIf="!isProposalGenerationLocked()"
                                    buttonText="Generate Presentation"
                                    buttonRightFontAwesomeIcon="fas fa-arrow-circle-right"
                                    buttonClass="btn-default"
                                    containerClass="action-tooltip-popover"
                                    placement="bottom"
                                    container="body"
                                    [popover]="generate_presentation_tooltip_template"
                                    triggers="manual"
                                    [isOpen]="isPopoverOpen"
                                    [buttonDisabled]="isLoading"
                                    #pop="bs-popover"
                                    (buttonClicked)="onGeneratePresentationClick(pdfCheckList.checklist)"></sei-button>
                        <!-- Disabled Button with tooltip only in case of locked proposal -->
                        <sei-button *ngIf="isProposalGenerationLocked()"
                                    buttonText="Generate Presentation"
                                    buttonRightFontAwesomeIcon="fas fa-arrow-circle-right"
                                    buttonClass="btn-default"
                                    [buttonDisabled]="true"
                                    [popover]="getLockedTooltip()"
                                    [triggers]="'hover'"
                                    #pop="bs-popover"
                                    container="body">
                        </sei-button>
                    </div>
                </div>
            </div>
            <div class="prev-column col-xl-6 col-lg-5 col-md-4 col-sm-3">
                <sei-car-preview [pdfCheckList]="pdfCheckList.checklist"></sei-car-preview>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #generate_presentation_tooltip_template>
    <div class="delete-tooltip-panel"
         id="generatePresentation"
         *ngIf="isPDFCreated && isPopoverOpen">
        <div class="row">
            <div class="col-xs-12">
                <h3>Override PDF Presentation?</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 car-main-sm-body-copy form-group delete-text">
                Are you sure you want to override presentation already created?
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 car-main-sm-body-copy form-group">
                <sei-button class="btn-in-tooltip"
                            buttonText="Cancel"
                            buttonLeftFontAwesomeIcon="fas fa-times-circle"
                            buttonClass="btn-default"
                            (buttonClicked)="onConfirmOverrideCancel()">
                </sei-button>
                <sei-button class="btn-in-tooltip"
                            buttonText="OK"
                            buttonRightFontAwesomeIcon="far fa-trash-alt trash-button-icon"
                            buttonClass="btn-default"
                            [buttonDisabled]="isLoading"
                            (buttonClicked)="onConfirmOverrideClick()">
                </sei-button>
            </div>
        </div>
    </div>
</ng-template>

<sei-modal-window #deleteModal
                  [modalTitle]="modalTitle"
                  [isDismissible]="true"
                  [footerEnable]="true">
    <div class="modal-body sei-margin-bottom-lg">
        <p>Deleting this content will remove it from all presentations that have not yet been generated.</p>
        <p>Are you sure you want to delete the document?</p>
    </div>
    <footer class="modalbox-footer">
        <sei-button class="pull-left"
                    buttonText="Cancel"
                    [buttonClass]="cancelButtonClass"
                    (click)="cancelModal()"></sei-button>
        <sei-button buttonText="Confirm"
                    [buttonClass]="confirmButtonClass"
                    (click)="confirmModal()"></sei-button>
    </footer>
</sei-modal-window>
