<div *ngIf="showExisting; then existing else proposed"></div>
<ng-template #proposed>
    <div [ngClass]="proposalAccountService.isAccountIncomplete(wipCheckList.mdcSnapShot) ? 'wip-section-background-gray' : 'wip-section-background-green'"
         class="wip-section-padding-bottom wip-section-border"
         [class.wip-section-border-rounded]="isLast">
        <div class="wip-summary-row head account-head">
            <div class="wip-summary-left">
                <p class="indent-overflow account-text">
                    <car-wip-checklist-status-indicator [indentContent]="true"
                                                        [mdcSnapShot]="wipCheckList.mdcSnapShot"></car-wip-checklist-status-indicator>{{ wipCheckList.name }}
                    <i *ngIf="account?.isAccountNameOverMaximumLength" class="fas fa-info-circle fas-info fa-sm account-name-info-alert"
                                    [popover]="accountLengthWarning" container="body" #pop="bs-popover" [triggers]="'hover'"
                                    [placement]="'right'"></i>
                </p>
            </div>
            <div class="wip-summary-right">
                <car-wip-checklist-actions [wipCheckList]="wipCheckList"
                                           [sectionType]="sectionType"
                                           [buttonDisabled]="buttonDisabled">
                </car-wip-checklist-actions>
            </div>
        </div>
        <div class="wip-summary-row account-details">
            <div class="wip-summary-left row">
                <div class="col-xs-6">
                    <span>Account Market Value</span>
                </div>
                <div class="col-xs-6">
                    <span *ngIf="!isPortfolioAssetDataLoading" id="marketValue">
                    {{ { value: calculateAccountBalanceWithNonModel(account), actualCurrency: 'USD'} | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix }}
                    </span>
                    <span *ngIf="isPortfolioAssetDataLoading">
                    <sei-loading multiplier="1" [showLoadingIcon]="true"></sei-loading>
                </span>
                </div>
            </div>
        </div>
        <div class="wip-summary-row account-details">
            <div class="wip-summary-left row">
                <div class="col-xs-6">
                    <span>Risk Tolerance</span>
                </div>
                <div class="col-xs-6">
                    <span *ngIf="checkIfRiskStatusIsIncomplete()" class="warning-text-red"><i class="fas fa-times-circle"></i> Incomplete</span>
                    <span *ngIf="!checkIfRiskStatusIsIncomplete()">{{ selectedAccountRiskValue }}</span>
                </div>
            </div>
        </div>
        <div class="wip-summary-row pageheader account-body-row">
            <div class="col-xs-12 indent-left">
                <sei-pageheader [headingLevel]="5"
                                [pageHeaderProperties]="modelPageHeaderProperties">
                    <div extra-content>
                        <span class="header-amount">{{ { value: calculateAccountModelBalance(account), actualCurrency: 'USD' }| seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}</span>
                        <a class="section-arrow" [class.open]="showModelInvestmentDetails" aria-label="Expand Investment Details Section" (click)="toggleShowModelInvestmentDetails()">
                        <i class="fas" [class.fa-chevron-right]="!showModelInvestmentDetails" [class.fa-chevron-down]="showModelInvestmentDetails"></i>
                    </a>
                    </div>
                </sei-pageheader>
            </div>
        </div>
        <div *ngIf="showModelInvestmentDetails">
            <div *ngIf="checkIfAccountHasSelectedRiskMethod() && !account.strategies.length"
                 class="wip-summary-row account-body-row">
                <span class="warning-text-red"><i class="fas fa-times-circle"></i> Not assigned</span>
            </div>
            <div class="wip-summary-row account-body-row"
                 *ngFor="let model of listOfSubstitutionsByStrategy; let i = index">
                <div class="indent-left investment-row">
                    <div class="col-xs-6">
                        <span>{{ model.strategyName }}</span>
                    </div>
                    <div class="col-xs-6">
                        <span class="pull-right">{{ {value: model.strategyAmount, actualCurrency: 'USD' } | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}</span>
                    </div>
                    <div class="substitution-row col-xs-12">
                        <sei-button *ngIf="isSubstitutionEnabled && model.strategySubstitutions?.length > 0"
                                    [buttonText]="'Personalized'"
                                    [buttonClass]="'btn-primary'"
                                    [buttonSize]="buttonSize"
                                    (buttonClicked)="onClickPersonalize(listOfSubstitutionsByStrategy, i, model.isSubstitutionDetailsClicked)">
                        </sei-button>
                    </div>
                    <div class="investment-row"
                         *ngIf="isSubstitutionEnabled && model.isSubstitutionDetailsClicked">
                        <div class="substitution-row col-xs-12">
                            <div class="substitution-ribbon">
                                <a (click)="onClickPersonalize(listOfSubstitutionsByStrategy, i, model.isSubstitutionDetailsClicked)">
                                <i class="top-right fad fa-times-circle fa-lg" style="--fa-primary-color: #ffffff; --fa-secondary-color: #037ea6; --fa-secondary-opacity: 1;"></i>
                            </a>
                            </div>
                            <div class="substitution-box">
                                <div [class.substitution-details]="!isLast"
                                     *ngFor="let substitution of model.strategySubstitutions; let isLast=last">
                                    <p *ngIf="!model.isStandaloneStrategy">
                                        <strong>
                                        {{substitution.modelName}}
                                        </strong>
                                    </p>
                                    <p>
                                        <strong>Substitution:</strong>
                                        {{substitution.substitutionDetail}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!showModelInvestmentDetails && account.strategies.length > 0"
             class="wip-summary-row account-body-row">
            <div class="indent-left investment-row">
                <span class="show-more">
                    <i class="fas fa-info-circle"></i> {{ SHOW_MORE_MESSAGE }}
                </span>
            </div>
        </div>
        <car-wip-checklist-non-models [portfolios]="filterOutDeletedPortfolios(account?.portfolios)"
                                      [isPortfolioAssetDataLoading]="isPortfolioAssetDataLoading"></car-wip-checklist-non-models>
    </div>
</ng-template>

<ng-template #existing>
    <div [ngClass]="proposalAccountService.isAccountIncomplete(getMdcSnapshot()) ? 'wip-section-background-gray' : 'wip-section-background-green'"
         [class.wip-section-border-rounded]="isLast"
         class="wip-section-border wip-section-padding-bottom"
         *ngIf="account">
        <div class="wip-summary-row head">
            <div class="wip-summary-left name-container">
                <p class="indent-overflow account-text">
                    <car-wip-checklist-status-indicator [mdcSnapShot]="getMdcSnapshot()"
                                                        [indentContent]="true"></car-wip-checklist-status-indicator>
                    {{ existingAccountData?.accountName }}
                </p>
                <div *ngIf="account?.isCurrentAccountInvestmentsModified"
                     class="modified-tag">Modified</div>

            </div>
            <div class="wip-summary-right">
                <sei-button buttonText="Modify Investments"
                            buttonClass="sei-btn-link-padding-none"
                            (buttonClicked)="onModifyInvestmentsClicked()"></sei-button>
            </div>
        </div>
        <div class="wip-summary-row account-body-row"
             id="marketValueSection">
            <div class="wip-summary-left indent-left">
                <div class="col-xs-6">
                    <span>Account Market Value</span>
                </div>
                <div class="col-xs-6">
                    <span>{{ {value: getAccountMarketValue(), actualCurrency: 'USD' } | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}
                    </span>
                </div>
            </div>
        </div>
        <div class="wip-summary-row account-body-row"
             id="newFundsValueSection">
            <div class="wip-summary-left indent-left">
                <div class="col-xs-6">
                    <span>{{ removeFundsFromExistingAccount ? 'Removed Funds' : 'Additional Funds' }}</span>
                </div>
                <div class="col-xs-6">
                    <span>{{ {value: getNewFunds() , actualCurrency: 'USD' } | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}</span>
                </div>
            </div>
        </div>
        <div class="wip-summary-row account-body-row"
             id="proposedAmountSection">
            <div class="wip-summary-left indent-left">
                <div class="col-xs-6">
                    <span>Total Proposed Amount</span>
                </div>
                <div class="col-xs-6">
                    <span>{{ {value: getTotalProposedAmount() , actualCurrency: 'USD' } | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}</span>
                </div>
            </div>
        </div>
        <div class="wip-summary-row pageheader account-body-row">
            <div class="col-xs-12 indent-left">
                <sei-pageheader [headingLevel]="5"
                                [pageHeaderProperties]="modelPageHeaderProperties">
                    <div extra-content>
                        <span class="market-value-header">{{ {value: getModelInvestmentValue(), actualCurrency: 'USD' } | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}</span>
                        <a class="section-arrow" [class.open]="showModelInvestmentDetails" aria-label="Expand Investment Details Section" (click)="toggleShowModelInvestmentDetails()">
                                    <i class="fas" [class.fa-chevron-right]="!showModelInvestmentDetails" [class.fa-chevron-down]="showModelInvestmentDetails"></i>
                                </a>
                    </div>
                </sei-pageheader>
            </div>
        </div>
        <div *ngIf="showModelInvestmentDetails">
            <div *ngIf="!account.strategies.length"
                 class="wip-summary-row account-body-row">
                <span class="warning-text-red"><i class="fas fa-times-circle"></i> Not assigned</span>
            </div>
            <div class="wip-summary-row account-body-row"
                 *ngFor="let model of listOfSubstitutionsByStrategy; let i = index">
                <div class="indent-left investment-row">
                    <div class="col-xs-6">
                        <span>{{ model.strategyName }}</span>
                    </div>
                    <div class="col-xs-6">
                        <span class="pull-right">{{ {value: model.strategyAmount, actualCurrency: 'USD' } | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}</span>
                    </div>
                    <div class="substitution-row col-xs-12">
                        <sei-button *ngIf="isSubstitutionEnabled && model.strategySubstitutions?.length > 0"
                                    [buttonText]="'Personalized'"
                                    [buttonClass]="'btn-primary'"
                                    [buttonSize]="buttonSize"
                                    (buttonClicked)="onClickPersonalize(listOfSubstitutionsByStrategy, i, model.isSubstitutionDetailsClicked)">
                        </sei-button>
                    </div>
                    <div class="investment-row"
                         *ngIf="isSubstitutionEnabled && model.isSubstitutionDetailsClicked">
                        <div class="substitution-row col-xs-12">
                            <div class="substitution-ribbon">
                                <a (click)="onClickPersonalize(listOfSubstitutionsByStrategy, i, model.isSubstitutionDetailsClicked)">
                                <i class="top-right fad fa-times-circle fa-lg" style="--fa-primary-color: #ffffff; --fa-secondary-color: #037ea6; --fa-secondary-opacity: 1;"></i>
                            </a>
                            </div>
                            <div class="substitution-box">
                                <div [class.substitution-details]="!isLast"
                                     *ngFor="let substitution of model.strategySubstitutions; let isLast=last">
                                    <p *ngIf="!model.isStandaloneStrategy">
                                        <strong>
                                        {{substitution.modelName}}
                                        </strong>
                                    </p>
                                    <p>
                                        <strong>Substitution:</strong>
                                        {{substitution.substitutionDetail}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!showModelInvestmentDetails && account.strategies.length > 0"
             class="wip-summary-row account-body-row">
            <div class="indent-left investment-row">
                <span class="show-more">
                            <i class="fas fa-info-circle"></i> {{ SHOW_MORE_MESSAGE }}
                        </span>
            </div>
        </div>
        <car-wip-checklist-non-models [portfolios]="filterOutDeletedPortfolios(account?.portfolios)"
                                      [isPortfolioAssetDataLoading]="isPortfolioAssetDataLoading"></car-wip-checklist-non-models>
    </div>
</ng-template>
