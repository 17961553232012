<div class="wip-summary-row sectionhead wip-checklist-section-header wip-checklist-section-header-bg-gray"
     [class.wip-checklist-section-header-collapsed]="!areGoalsPresent() || showGoalsDetail">
    <div class="wip-summary-left subhead">
        <car-wip-checklist-status-indicator class="header-icon"
                                            [mdcSnapShot]="getGoalsSectionStatus()"></car-wip-checklist-status-indicator><h4>Goals</h4>
    </div>
    <div class="wip-summary-right">
        <sei-button buttonText="{{ areGoalsPresent() ? 'Edit' : 'Add' }}"
                    buttonClass="btn-link"
                    [buttonDisabled]="!enableGoalsFlyout()"
                    (buttonClicked)="navigate()"></sei-button> <span [hidden]="!wipCheckListGoals?.subCheckList">|</span>
        <a  [hidden]="!wipCheckListGoals.subCheckList"
            class="section-arrow"
            aria-label="Expand Proposed Accounts Section"
            (click)="onShowDetailClick()">
                <i class="fas" [class.fa-chevron-right]="showGoalsDetail" [class.fa-chevron-down]="!showGoalsDetail"></i>
        </a>
    </div>
</div>
<div *ngIf="wipCheckListGoals"
     [hidden]="showGoalsDetail">
    <div *ngFor="let subCheckList of wipCheckListGoals?.subCheckList; let isLast = last"
         class="wip-goals-summary goals-head wip-section-background-green wip-section-border wip-section-border-rounded"
         [class.wip-section-border-rounded]="isLast"
         [class.wip-section-background-gray]="!getGoalStatusFromWIPChecklistObject(subCheckList)">

        <div class="wip-summary-row goal-name">
            <div class="wip-summary-left">
                <car-wip-checklist-status-indicator [mdcSnapShot]="getGoalStatus(subCheckList)"></car-wip-checklist-status-indicator>
                <p class="subhead section-name">
                    {{subCheckList.name }}
                </p>
            </div>
        </div>
        <ng-container *ngIf="subCheckList.mdcSnapShot.completed !== goalStatus.New">
            <div class="wip-summary-row">
                <div class="wip-summary-left fee-margin-left">
                    <span>Goal Type</span>
                </div>
                <div class="wip-summary-right">
                    <span>{{subCheckList.mdcSnapShot.goalType || '&mdash;'}}</span>
                </div>
            </div>
            <div class="wip-summary-row">
                <div class="wip-summary-left fee-margin-left">
                    <span>Priority</span>
                </div>
                <div class="wip-summary-right">
                    <span>{{subCheckList.mdcSnapShot.goalPriority || '&mdash;'}}</span>
                </div>
            </div>
            <div class="wip-summary-row">
                <div class="wip-summary-left fee-margin-left">
                    <span>{{getDateLabel(subCheckList)}}</span>
                </div>
                <div class="wip-summary-right">
                    <span>{{getDateString(subCheckList)}}</span>
                </div>
            </div>
            <div class="wip-summary-row"
                 *ngIf="showFrequency(subCheckList)">
                <div class="wip-summary-left fee-margin-left">
                    <span>Frequency</span>
                </div>
                <div class="wip-summary-right">
                    <span>{{getFrequencyValue(subCheckList)}}</span>
                </div>
            </div>
            <div class="wip-summary-row"
                 *ngIf="isRetirement(subCheckList)">
                <div class="wip-summary-left fee-margin-left">
                    <span>Current Annual Income</span>
                </div>
                <div class="wip-summary-right">
                    <sei-car-display-monetary-amount [amount]="getCurrentAnnualIncome(subCheckList)"
                                                     [actualCurrency]="currencyTypes.USD">
                    </sei-car-display-monetary-amount>
                </div>
            </div>
            <div class="wip-summary-row"
                 *ngIf="isRetirement(subCheckList)">
                <div class="wip-summary-left fee-margin-left">
                    <span>Percent of Income Needed</span>
                </div>
                <div class="wip-summary-right">
                    <span>{{getPercentOfIncomeNeeded(subCheckList)}}%</span>
                </div>
            </div>
            <div class="wip-summary-row"
                 *ngIf="isEducation(subCheckList)">
                <div class="wip-summary-left fee-margin-left">
                    <span>Annual Expenses</span>
                </div>
                <div class="wip-summary-right">
                    <sei-car-display-monetary-amount [amount]="getAnnualExpenses(subCheckList)"
                                                     [actualCurrency]="currencyTypes.USD">
                    </sei-car-display-monetary-amount>
                </div>
            </div>
            <div class="wip-summary-row"
                 *ngIf="!isRetirement(subCheckList) && !isEducation(subCheckList)">
                <div class="wip-summary-left fee-margin-left">
                    <span>Amount</span>
                </div>
                <div class="wip-summary-right">
                    <sei-car-display-monetary-amount *ngIf="getAmount(subCheckList)"
                                                     [amount]="getAmount(subCheckList)"
                                                     [actualCurrency]="currencyTypes.USD">
                    </sei-car-display-monetary-amount>

                    <span *ngIf="!getAmount(subCheckList)">
                        &mdash;
                    </span>

                </div>
            </div>
        </ng-container>
    </div>
</div>
