/*
Copyright: This information constitutes the exclusive property of SEI
Investments Company, and constitutes the confidential and proprietary
information of SEI Investments Company.  The information shall not be
used or disclosed for any purpose without the written consent of SEI
Investments Company.
*/

import { Component, Input, OnInit } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Scenario } from '@sei/advisor-client-review-proposal-ux';
import { ProposalSectionLabel } from '../../../model/enums';
import { PresentationBusinessRules, ProposalSection, ProposalStatement } from '../../../model/presentation';
import { DataSharingService } from '../../../service/data-sharing.service';

@Component({
    selector: 'sei-car-ips-section',
    templateUrl: './ips-section.component.html'
})
export class IpsSectionComponent extends ParentComponentSubscriptionManager implements OnInit {
    @Input()
    public statement: ProposalStatement;

    @Input()
    public businessRules: PresentationBusinessRules;

    @Input()
    public scenario: Scenario;

    public proposalSectionLabelTypes: typeof ProposalSectionLabel = ProposalSectionLabel;

    constructor(private readonly dataSharingService: DataSharingService) {
        super('IpsSectionComponent');
    }

    public ngOnInit(): void {
        this.subscriptions.push(this.dataSharingService.proposalSectionChanged.subscribe((response: ProposalSection) => {
            this.updateSections(response);
        }));
    }

    public parentToggleChange(isToggled: boolean, section: ProposalSection): void {
        section.sections.forEach((proposalSectionElement: ProposalSection) => {
            proposalSectionElement.include = isToggled;
            proposalSectionElement.sections.forEach((proposalSectionChild: ProposalSection) =>
                proposalSectionChild.include = isToggled
            );
        });
    }

    public activateSection(section: ProposalSection): void {
        this.dataSharingService.proposalSectionChanged.next(section);
    }

    public onClickPreview(section: ProposalSection): void {
        this.dataSharingService.proposalSectionChanged.next(section);
    }

    public updateSections(currentSection: ProposalSection): void {
        this.statement.sections.forEach((elementProposalSection: ProposalSection) => {
            elementProposalSection.isActive = elementProposalSection === currentSection;
        });
    }
}
