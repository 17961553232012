<div *ngIf="isMonteCarloFeatureEnabled; then monteCarloCard else dashboardTile">
</div>

<ng-template #dashboardTile>
    <sei-dashboard-tile [title]="titleText"
                        panelClass="panel-default">
        <div panel-heading-extra-content>
            <sei-button buttonClass="btn-link"
                        buttonRightFontAwesomeIcon="fa-trash-alt"
                        (buttonClicked)="fireRemoveEvent()"></sei-button>
        </div>
        <div panel-content>
            <div class="form-group">
                <div class="row">
                    <div class="col-xs-6 form-group"
                         [class.has-error]="isNameOverMaximumLength">
                        <label for="name-{{index}}"
                               class="card-label">Goal Name</label>
                        <input type="text"
                               class="form-control"
                               [class.border-red]="isNameOverMaximumLength"
                               id="name-{{index}}"
                               name="name-{{index}}"
                               [ngModel]="goal.name"
                               (ngModelChange)="onGoalNameChange($event)" />
                        <span *ngIf="isNameOverMaximumLength" class="help-block">
                        Goal Name must be less than 66 characters
                    </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6">
                        <label for="goal-type-{{index}}"
                               class="card-label">Type</label>
                        <sei-dropdown name="StrategyFilter"
                                      id="goal-type-{{index}}"
                                      [items]="goalCatalogs.types"
                                      placeholder="Select"
                                      [ngModel]="selectedOptionValues.types"
                                      (ngModelChange)="onDropDownChange($event, goalSelectionOptions.GoalType)">
                        </sei-dropdown>
                    </div>
                    <div class="col-xs-6">
                        <label for="goal-priority-{{index}}"
                               class="card-label">Priority</label>
                        <sei-dropdown name="StrategyFilter"
                                      id="goal-priority-{{index}}"
                                      [items]="goalCatalogs.priorities"
                                      placeholder="Select"
                                      [ngModel]="selectedOptionValues.priorities"
                                      (ngModelChange)="onDropDownChange($event, goalSelectionOptions.Priority)">
                        </sei-dropdown>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6">
                        <label for="start-date-{{index}}"
                               class="card-label">Start Date <i class="card-tooltip-icon fas fa-info-circle fas-info circle-icon" placement="right" [tooltip]="tipContent"></i></label>
                        <sei-calendar id="start-date-{{index}}"
                                      [minDate]="minDateAllowed"
                                      [startAt]="today"
                                      [ngModel]="goal.startDate"
                                      (ngModelChange)="onStartDateChange($event)">
                        </sei-calendar>
                    </div>
                    <div class="col-xs-6">
                        <label for="end-date-{{index}}"
                               class="card-label">End Date</label>
                        <sei-calendar id="end-date-{{index}}"
                                      [minDate]="goal.startDate"
                                      [startAt]="tomorrow"
                                      [ngModel]="goal.endDate"
                                      (ngModelChange)="onEndDateChange($event)">
                        </sei-calendar>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6">
                        <label for="end-value-{{index}}"
                               class="card-label">Amount</label>
                        <input type="text"
                               placeholder="$"
                               class="form-control"
                               id="end-value-{{index}}"
                               name="end-value-{{index}}"
                               ngDefaultControl
                               seiEditMonetaryAmount
                               [amountOptions]="amountOptions"
                               [actualCurrency]="this.currencyTypes.USD"
                               [class.alert]="!this.endValueIsValid"
                               [value]="goal.endValue"
                               (output)="onEndValueChange($event)"
                               maxlength="15" />
                        <car-alert-popover *ngIf="!this.endValueIsValid"
                                           [alertType]="alertPopoverTypes.Error"
                                           [alertMessage]="balanceErrorMessage"></car-alert-popover>
                    </div>
                    <div class="col-xs-6">
                        <label for="frequency-{{index}}"
                               class="card-label">Frequency</label>
                        <sei-dropdown id="frequency-{{index}}"
                                      name="StrategyFilter"
                                      [items]="goalCatalogs.frequencies"
                                      placeholder="Select"
                                      [ngModel]="selectedOptionValues.frequencies"
                                      (ngModelChange)="onDropDownChange($event,goalSelectionOptions.Frequency)">
                        </sei-dropdown>
                    </div>
                </div>
            </div>
            <ng-template #tipContent>{{dateInfoText}}</ng-template>
        </div>
    </sei-dashboard-tile>
</ng-template>

<ng-template #monteCarloCard>
    <div id="card-contents-{{index}}"
         class="monte-carlo-goal-card-container">
        <div class="monte-carlo-goal-card-header-container">
            <div id="card-header-{{index}}"
                 class="monte-carlo-goal-card-header-title">
                <h2>Goal {{index}}</h2>
            </div>
            <div class="monte-carlo-goal-card-header-buttons">
                <div class="clear-selections-button">
                    <sei-button buttonText="Clear Values"
                                buttonRightFontAwesomeIcon="fa-undo"
                                buttonClass="btn-link"
                                (click)="resetGoalForm()"
                                id="reset-goals-{{index}}"></sei-button>
                </div>
                <i class="fas fa-times-circle" (click)="fireRemoveEvent()"></i>
            </div>
        </div>


        <div id="goal-form-{{index}}"
             class="monte-carlo-goal-card-form-container">
            <div class="form-group">
                <div class="row">
                    <div class="col-xs-4 form-group"
                         [class.has-error]="isNameOverMaximumLength || (goalName.touched && goal.name.length === 0)">
                        <label for="name-{{index}}"
                               class="card-label">Goal Name</label>
                        <input type="text"
                               class="form-control"
                               [class.border-red]="isNameOverMaximumLength || (goalName.touched && goal.name.length === 0)"
                               id="name-{{index}}"
                               name="name-{{index}}"
                               [ngModel]="goal.name"
                               (ngModelChange)="onGoalNameChange($event)"
                               required
                               #goalName="ngModel" />
                        <span *ngIf="isNameOverMaximumLength" class="help-block">
                            Goal Name must be less than 66 characters
                        </span>
                        <span *ngIf="goalName.touched && goal.name.length === 0" class="help-block">
                            Goal Name is required.
                        </span>
                    </div>
                    <div class="col-xs-4">
                        <div class="form-group"
                             [class.has-error]="isGoalTypeInvalid(goalType.touched, index)">
                            <label for="goal-type-{{index}}"
                                   class="card-label">Type</label>
                            <sei-dropdown name="StrategyFilter"
                                          id="goal-type-{{index}}"
                                          [items]="goalCatalogs.types"
                                          placeholder="Select"
                                          [ngModel]="selectedOptionValues.types"
                                          (ngModelChange)="onDropDownChange($event, goalSelectionOptions.GoalType)"
                                          required
                                          #goalType="ngModel">
                            </sei-dropdown>
                            <span *ngIf="isGoalTypeInvalid(goalType.touched, index)" class="help-block">
                                Goal Type is required.
                            </span>
                        </div>
                    </div>
                    <div class="col-xs-4">
                        <div class="form-group"
                             [class.has-error]="goalPriority.touched && isGoalPriorityInvalid()">
                            <label for="goal-priority-{{index}}"
                                   class="card-label">Priority</label>
                            <sei-dropdown name="goal-priority-{{index}}"
                                          [items]="goalCatalogs.priorities"
                                          placeholder="Select"
                                          [ngModel]="selectedOptionValues.priorities"
                                          (ngModelChange)="onDropDownChange($event, goalSelectionOptions.Priority)"
                                          required
                                          #goalPriority="ngModel">
                            </sei-dropdown>
                            <span *ngIf="goalPriority.touched && isGoalPriorityInvalid()" class="help-block">
                                Goal Priority is required.
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4"
                         *ngIf="showStartDate()">
                        <div class="form-group"
                             [class.has-error]="goalStartDate.touched && !goal.startDate">
                            <label for="start-date-{{index}}"
                                   class="card-label">{{getGoalStartDateLabelText()}} <i class="card-tooltip-icon fas fa-info-circle fas-info circle-icon" placement="right" [tooltip]="tipContent"></i></label>
                            <sei-calendar id="start-date-{{index}}"
                                          [minDate]="minDateAllowed"
                                          [startAt]="today"
                                          [ngModel]="goal.startDate"
                                          (ngModelChange)="onStartDateChange($event)"
                                          #goalStartDate="ngModel">
                            </sei-calendar>
                            <span *ngIf="goalStartDate.touched && !goal.startDate"
                               class="help-block">
                            {{getGoalStartDateLabelText()}} is Required.
                            </span>
                        </div>
                    </div>
                    <div class="col-xs-4">
                        <div class="form-group"
                             [class.has-error]="goalEndDate.touched && !goal.endDate">
                            <label for="end-date-{{index}}"
                                   class="card-label">{{getGoalEndDateLabelText()}}</label>
                            <sei-calendar id="end-date-{{index}}"
                                          [minDate]="goal.startDate"
                                          [startAt]="tomorrow"
                                          [ngModel]="goal.endDate"
                                          (ngModelChange)="onEndDateChange($event)"
                                          #goalEndDate="ngModel">
                            </sei-calendar>
                            <span *ngIf="goalEndDate.touched && !goal.endDate"
                               class="help-block">
                            {{getGoalEndDateLabelText()}} is Required.
                            </span>
                        </div>
                    </div>
                    <div class="col-xs-4 form-group"
                         [class.has-error]="targetAmountHasErrors"
                         [hidden]="!this.monteCarloFormOptions?.targetAmount?.show">
                        <label for="target-value-{{index}}"
                               class="card-label">Goal Amount</label>
                        <input type="text"
                               placeholder="$"
                               class="form-control"
                               [class.border-red]="targetAmountHasErrors"
                               id="target-value-{{index}}"
                               name="target-value-{{index}}"
                               ngDefaultControl
                               seiEditMonetaryAmount
                               [amountOptions]="amountOptions"
                               [actualCurrency]="this.currencyTypes.USD"
                               [value]="goal.monteCarloAnalytics.targetAmount"
                               (output)="onTargetAmountChange($event)"
                               (error)="setTargetAmountError($event)"
                               maxlength="15" />
                        <span *ngIf="targetAmountHasErrors"
                           class="help-block">
                            Goal Amount is required.
                </span>
                    </div>
                    <div class="col-xs-4"
                         *ngIf="goalOptionTypeIds?.Education === goal?.goalType?.id">
                        <label for="annual-expenses-{{index}}"
                               class="card-label">Annual Expenses</label>
                        <input type="text"
                               placeholder="$"
                               class="form-control"
                               id="annual-expenses-{{index}}"
                               name="annual-expenses-{{index}}"
                               ngDefaultControl
                               seiEditMonetaryAmount
                               [amountOptions]="amountOptions"
                               [actualCurrency]="this.currencyTypes.USD"
                               [value]="goal?.monteCarloAnalytics?.annualExpenses"
                               (output)="onAnnualExpensesChange($event)"
                               maxlength="15" />
                    </div>
                </div>
                <ng-container *ngIf="monteCarloFormOptions?.distributionAmount?.show || monteCarloFormOptions?.distributionFrequency.show">
                    <div class="row">
                        <div class="col-xs-4 form-group"
                             [hidden]="!monteCarloFormOptions?.distributionAmount?.show"
                             [class.has-error]="distributionAmountHasErrors">
                            <label for="dist-amount-{{index}}"
                                   class="card-label">Distribution Amount</label>
                            <input type="text"
                                   placeholder="$"
                                   class="form-control"
                                   [class.border-red]="distributionAmountHasErrors"
                                   id="dist-amount-{{index}}"
                                   name="dist-amount-{{index}}"
                                   ngDefaultControl
                                   seiEditMonetaryAmount
                                   [amountOptions]="amountOptions"
                                   [actualCurrency]="this.currencyTypes.USD"
                                   [value]="goal.monteCarloAnalytics.distributionAmount"
                                   [disabled]="monteCarloFormOptions?.distributionFrequency?.disable"
                                   (output)="onDistributionAmountChange($event)"
                                   (error)="setDistributionAmountError($error)"
                                   maxlength="15" />
                            <span *ngIf="distributionAmountHasErrors" class="help-block">
                            Distribution Amount is required.
                        </span>
                        </div>
                        <div class="col-xs-4"
                             [hidden]="!monteCarloFormOptions?.distributionFrequency?.show">
                            <div class="form-group"
                                 [class.has-error]="distributionFrequency.touched && isDistributionFrequencyInvalid()">
                                <label for="dist-frequency-{{index}}"
                                       class="card-label">Distribution Frequency</label>
                                <sei-dropdown id="dist-frequency-{{index}}"
                                              [items]="distributionFrequencyDropdownItems"
                                              [disabled]="monteCarloFormOptions?.distributionFrequency?.disable"
                                              [ngModel]="selectedOptionValues.distributionFrequency"
                                              (ngModelChange)="onDropDownChange($event, goalSelectionOptions.DistributionFrequency)"
                                              [multiSelect]="false"
                                              required
                                              #distributionFrequency="ngModel"></sei-dropdown>
                                <span *ngIf="distributionFrequency.touched && isDistributionFrequencyInvalid()" class="help-block">
                            Distribution Frequency is required.
                        </span>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="goal?.goalType?.id === goalOptionTypeIds?.Retirement">
                    <div class="row">
                        <div class="col-xs-4 form-group"
                             [class.has-error]="currentAnnualIncomeHasErrors">
                            <label for="annual-income-{{index}}"
                                   class="card-label">Current Annual Income</label>
                            <input type="text"
                                   placeholder="$"
                                   class="form-control"
                                   [class.border-red]="currentAnnualIncomeHasErrors"
                                   id="annual-income-{{index}}"
                                   name="annual-income-{{index}}"
                                   ngDefaultControl
                                   seiEditMonetaryAmount
                                   [amountOptions]="amountOptions"
                                   [actualCurrency]="this.currencyTypes.USD"
                                   [value]="goal?.monteCarloAnalytics?.currentAnnualIncome"
                                   (output)="onCurrentAnnualIncomeChange($event)"
                                   (error)="setCurrentAnnualIncomeError($error)"
                                   maxlength="15" />
                            <span *ngIf="currentAnnualIncomeHasErrors" class="help-block">
                            Current Annual Income is required.
                        </span>
                        </div>
                        <div class="col-xs-4 form-group"
                             [class.has-error]="percentOfIncomeNeededHasErrors">
                            <label for="percent-income-{{index}}"
                                   class="card-label">Percent of Income Needed</label>
                            <input type="text"
                                   placeholder="%"
                                   class="form-control"
                                   [class.border-red]="percentOfIncomeNeededHasErrors"
                                   id="percent-income-{{index}}"
                                   name="percent-income-{{index}}"
                                   ngDefaultControl
                                   seiEditPercent
                                   [percentOptions]="amountOptions"
                                   [value]="goal.monteCarloAnalytics?.percentOfIncomeNeeded"
                                   (output)="onPercentOfIncomeNeededChange($event)"
                                   (error)="setPercentOfIncomeNeededError($error)"
                                   maxlength="5" />
                            <span *ngIf="percentOfIncomeNeededHasErrors" class="help-block">
                            Percent of Income Needed is required.
                        </span>
                        </div>
                    </div>
                </ng-container>
                <div class="row">
                    <div class="col-xs-8">
                        <div class="form-group"
                             [class.has-error]="goalFundingSelection.touched && isGoalFundingDropdownInvalid()">
                            <label for="goal-funding-{{index}}"
                                   class="card-label">Funding</label>
                            <sei-dropdown id="goal-funding-{{index}}"
                                          [showSelectionOptions]="false"
                                          [multiSelect]="true"
                                          [autoSelectParent]="false"
                                          [items]="goalFundingDropdownItems"
                                          [displayAllSummaryToken]="false"
                                          [ngModel]="selectedGoalFundingDropdownItems"
                                          (ngModelChange)="onGoalFundingDropdownChange($event)"
                                          (isTouched)="onGoalDropdownTouch()"
                                          #goalFundingSelection="ngModel">
                                <ng-template #defaultItem
                                             let-item="item"
                                             let-depth="depth"
                                             let-toggleCollapse="toggleCollapse"
                                             let-showCheckboxes="showCheckboxes"
                                             let-itemClicked="itemClicked"
                                             let-setItemActive="setItemActive"
                                             let-emitKeyDown="emitKeyDown"
                                             let-isExpanded="isExpanded"
                                             let-isSelected="isSelected"
                                             let-isIndeterminate="isIndeterminate"
                                             let-isActive="isActive"
                                             let-isSearchMatch="isSearchMatch">

                                    <div [class.disable-hover-styling]="doesDropdownItemMatchGivenElementType(item, goalDropDownLabelHelperText?.ACCOUNT_HEADER)">
                                        <a class="dropdown-item item-{{item.id}}"
                                            href="javascript:void(0);"
                                            [class.active]="isActive()"
                                            [class.search-match]="isSearchMatch()"
                                            [class.active-dropdown]="!showCheckboxes && (isSelected() && !doesDropdownItemMatchGivenElementType(item, goalDropDownLabelHelperText?.ACCOUNT_HEADER))"
                                            (focus)="setItemActive()"
                                            (keydown.space)="itemClicked($event)"
                                            (keydown)="emitKeyDown($event)"
                                            (click)="itemClicked($event)">

                                            <span class="fa-regular"
                                                style="margin-right: 8px"
                                                [class.goal-dropdown-item-disabled]="isGoalDropdownItemDisabled(item)"
                                                [class.dropdown-hide-checkbox]="doesDropdownItemMatchGivenElementType(item, goalDropDownLabelHelperText?.ACCOUNT_HEADER)"
                                                [class.fa-square]="!isSelected() && !isIndeterminate()"
                                                [class.fa-square-check]="isSelected()"
                                                [class.fa-square-minus]="isIndeterminate()"></span>

                                            <div [style.margin-left.px]="depth * 8"
                                                class="expand-collapse">
                                                <span class="fa-solid"
                                                    *ngIf="item.hasChildren"
                                                    [class.fa-angle-right]="!isExpanded()"
                                                    [class.fa-angle-down]="isExpanded()"
                                                    (click)="toggleCollapse($event)"></span>
                                            </div>

                                            <label for="{{item.name}}">
                                                <span
                                                    [class.dropdown-account-header]="doesDropdownItemMatchGivenElementType(item, goalDropDownLabelHelperText?.ACCOUNT_HEADER)"
                                                    [class.goal-dropdown-item-disabled]="isGoalDropdownItemDisabled(item)"
                                                    [innerHtml]="item.name | seiMultiWordHighlight:query"></span>
                                            </label>

                                        </a>
                                    </div>
                                </ng-template>
                            </sei-dropdown>
                            <span *ngIf="goalFundingSelection.touched && isGoalFundingDropdownInvalid()" class="help-block">
                                Funding is required.
                            </span>
                        </div>
                    </div>
                    <div class="col-xs-4">
                        <label for="init-contribution-{{index}}"
                               class="card-label">Initial Contribution</label>
                        <input type="text"
                               placeholder="$"
                               class="form-control"
                               [class.border-red]="initialContributionHasErrors"
                               id="init-contribution-{{index}}"
                               name="init-contribution-{{index}}"
                               ngDefaultControl
                               seiEditMonetaryAmount
                               [amountOptions]="amountOptions"
                               [actualCurrency]="this.currencyTypes.USD"
                               [value]="goal.monteCarloAnalytics.initialContribution"
                               (output)="onInitialContributionChange($event)"
                               maxlength="15"
                               readonly />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 form-group"
                         [class.has-error]="contributionAmountHasErrors">
                        <label for="contribution-amount-{{index}}"
                               class="card-label">Contribution Amount</label>
                        <input type="text"
                               placeholder="$"
                               class="form-control"
                               [class.border-red]="contributionAmountHasErrors"
                               id="contribution-amount-{{index}}"
                               name="contribution-amount-{{index}}"
                               ngDefaultControl
                               seiEditMonetaryAmount
                               [amountOptions]="amountOptions"
                               [actualCurrency]="this.currencyTypes.USD"
                               [value]="goal.endValue"
                               (output)="onContributionAmountChange($event)"
                               (error)="setContributionAmountError($error)"
                               maxlength="15" />
                        <span *ngIf="contributionAmountHasErrors" class="help-block">
                            Contribution Amount is required.
                        </span>
                    </div>
                    <div class="col-xs-4">
                        <div class="form-group"
                             [class.has-error]="contributionFrequency.touched && isContributionFrequencyInvalid()">
                            <label for="frequency"
                                   class="card-label">Contribution Frequency</label>
                            <sei-dropdown name="StrategyFilter"
                                          id="contribution-frequency-{{index}}"
                                          [items]="goalCatalogs.frequencies"
                                          placeholder="Select"
                                          [ngModel]="selectedOptionValues.frequencies"
                                          (ngModelChange)="onDropDownChange($event, goalSelectionOptions.Frequency)"
                                          required
                                          #contributionFrequency="ngModel">
                            </sei-dropdown>
                            <span *ngIf="contributionFrequency.touched && isContributionFrequencyInvalid()" class="help-block">
                                Contribution Frequency is required.
                            </span>
                        </div>
                    </div>
                    <div class="col-xs-4 form-group"
                         [class.has-error]="inflationRateHasErrors">
                        <label for="inflation-rate-{{index}}"
                               class="card-label">Inflation Rate</label>
                        <input type="text"
                               placeholder="%"
                               class="form-control"
                               [class.border-red]="inflationRateHasErrors"
                               id="inflation-rate-{{index}}"
                               name="inflation-rate-{{index}}"
                               ngDefaultControl
                               seiEditPercent
                               [percentOptions]="amountOptions"
                               [value]="goal.monteCarloAnalytics.inflationRate"
                               (output)="onInflationRateChange($event)"
                               (error)="setInflationRateError($error)"
                               maxlength="3" />
                        <span *ngIf="inflationRateHasErrors" class="help-block">
                            Inflation Rate is required.
                        </span>
                    </div>
                </div>

                <div class="row">
                    <div class="calculate-button-container">
                        <div class="button-wrapper">
                            <sei-button buttonText="Calculate Achievability"
                                        [buttonDisabled]="!goal.isValid || isMonteCarloRequestLoading"
                                        buttonClass="btn-primary"
                                        [class.no-border-radius-right]="showMonteCarloDrawer"
                                        (buttonClicked)="onCalculateAchievabilityClicked()"
                                        id="calculate-achievability-{{index}}"></sei-button>
                            <button class="btn btn-primary collapse-button"
                                    [class.show]="showMonteCarloDrawer"
                                    (click)="collapseClicked()"
                                    id="collapse-drawer-{{index}}"><i class="fas" [class.fa-chevron-right]="isDrawerCollapsed" [class.fa-chevron-down]="!isDrawerCollapsed"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #tipContent>{{dateInfoText}}</ng-template>
    </div>
    <sei-car-monte-carlo-analysis-drawer [class.show]="showMonteCarloDrawer && !isDrawerCollapsed && monteCarloCalculationAttributes && !isMonteCarloRequestLoading && !isMonteCarloRequestError"
                                         [class.loading]="showMonteCarloDrawer && !isDrawerCollapsed && monteCarloCalculationAttributes && (isMonteCarloRequestLoading || isMonteCarloRequestError)"
                                         [achievabilityAnalysisRequest]="monteCarloCalculationAttributes"
                                         [displayWarningMessage]="isFormDirty"
                                         (isMonteCarloRequestLoading)="setMonteCarloRequestLoading($event)"></sei-car-monte-carlo-analysis-drawer>
</ng-template>
