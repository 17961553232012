/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { ProposalTaxTransitionAnalysisCurrentAccountComponent } from '@CarComponents/tax-transition-analysis/tax-transition-analysis-current-account/tax-transition-analysis-current-account.component';
import { ProposalTaxTransitionAnalysisFormComponent } from '@CarComponents/tax-transition-analysis/tax-transition-analysis-form/tax-transition-analysis-form.component';
import { ProposalTaxTransitionAnalysisComponent } from '@CarComponents/tax-transition-analysis/tax-transition-analysis.component';
import { ENVIRONMENT } from '@CarModels/constants';
import { SpinnerService } from '@CarServices/system/spinner.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClientAccountAdministrationLibUxModule } from '@sei/client-account-administration-lib-ux';
import { DisplayMonetaryAmountPipe, DisplayPercentPipe, SeiCommonComponentsLibraryModule } from '@sei/common-components-lib-ux';
import { SeiCommonSwpComponentsLibraryModule } from '@sei/common-swp-components-lib-ux';
import { SeiCommonSwpWijmoLibraryModule } from '@sei/common-swp-wijmo-lib-ux';
import { SeiCommonWijmoLibraryModule, WijmoLicense } from '@sei/common-wijmo-lib-ux';
import { IasApplicationsLibUxModule } from '@sei/ias-applications-lib-ux';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ColorPickerModule } from 'ngx-color-picker';
import { PercentFormatPipe } from 'projects/advisor-client-review-proposal-ux-app/src/app/core/pipes/percent-format.pipe';
import { AccountAdvisorsDropdownComponent } from './common/account-advisors-dropdown/account-advisors-dropdown.component';
import { AccountOwnerDropdownComponent } from './common/account-owner-dropdown/account-owner-dropdown.component';
import { ActionToolTipPopoverComponent } from './common/action-tooltip-popover/action-tooltip-popover.component';
import { AdvisorDropdownComponent } from './common/advisor-dropdown/advisor-dropdown.component';
import { AlertPopoverComponent } from './common/alert-popover/alert-popover.component';
import { AltSourceImageComponent } from './common/alt-source-image/alt-source-image.component';
import { DisplayMonetaryAmountComponent } from './common/display-monetary-amount/display-monetary-amount.component';
import { DocumentSelectionComponent } from './common/document-selection/document-selection.component';
import { EntitySearchComponent } from './common/entity-search/entity-search.component';
import { FileDropDirective } from './common/file-upload/file-drop/file-drop.directive';
import { FileMultipleUploadComponent } from './common/file-upload/file-multiple-upload/file-multiple-upload.component';
import { FileUploadModalFooterComponent } from './common/file-upload/file-upload-modal-footer/file-upload-modal-footer.component';
import { FileUploadModalPanelComponent } from './common/file-upload/file-upload-modal-panel/file-upload-modal-panel.component';
import { GenericErrorNotificationComponent } from './common/generic-error-notification/generic-error-notification.component';
import { FeeStrategySummaryComponent } from './common/heads-up-display/fee-strategy-summary/fee-strategy-summary.component';
import { HeadsUpDisplayComponent } from './common/heads-up-display/heads-up-display.component';
import { ImageModalComponent } from './common/image-modal/image-modal.component';
import { InlineEditComponent } from './common/inline-edit/inline-edit.component';
import { NavigatorComponent } from './common/navigator/navigator.component';
import { OptionSelectionComponent } from './common/option-selection/option-selection.component';
import { PagingNextPreviousButtonComponent } from './common/paging-next-previous-button/paging-next-previous-button.component';
import { ReactivateModalComponent } from './common/reactivate/reactivate-modal.component';
import { RightHandDialogFooterComponent } from './common/right-hand-dialog-footer/right-hand-dialog-footer.component';
import { RightHandDialogComponent } from './common/right-hand-dialog/right-hand-dialog.component';
import { RiskSummaryWidgetComponent } from './common/risk-summary-widget/risk-summary-widget.component';
import { RiskWidgetComponent } from './common/risk-widget/risk-widget.component';
import { FirmAdvisorSearchComponent } from './common/select-firm-advisor/firm-advisor-search/firm-advisor-search.component';
import { SelectFirmAdvisorComponent } from './common/select-firm-advisor/select-firm-advisor.component';
import { SettingsDropdownComponent } from './common/settings-dropdown/settings-dropdown.component';
import { SettingsElementComponent } from './common/settings-element/settings-element.component';
import { SettingsNodeComponent } from './common/settings-node/settings-node.component';
import { StrategyMenuComponent } from './common/strategy-menu/strategy-menu.component';
import { ToggleButtonComponent } from './common/toggle-button/toggle-button.component';
import { ToggleIncludeExcludeComponent } from './common/toggle-include-exclude/toggle-include-exclude.component';
import { ToggleSeiWizardNavComponent } from './common/toggle-sei-wizard-nav/toggle-sei-wizard-nav.component';
import { DashboardTableComponent } from './dashboard-table/dashboard-table.component';
import { FeeBreakdownTooltipComponent } from './fees/fee-breakdown-tooltip/fee-breakdown-tooltip.component';
import { FeeScheduleAccountDetailComponent } from './fees/fee-schedule-account-detail/fee-schedule-account-detail.component';
import { FeeScheduleAccountComponent } from './fees/fee-schedule-account/fee-schedule-account.component';
import { FeeScheduleDropContainerComponent } from './fees/fee-schedule-drop-container/fee-schedule-drop-container.component';
import { FeeScheduleFeeRateComponent } from './fees/fee-schedule-fee-rate/fee-schedule-fee-rate.component';
import { FeeScheduleFirmComponent } from './fees/fee-schedule-firm/fee-schedule-firm.component';
import { FeeScheduleFlatFeeComponent } from './fees/fee-schedule-flat-fee/fee-schedule-flat-fee.component';
import { FeeScheduleRibbonComponent } from './fees/fee-schedule-ribbon/fee-schedule-ribbon.component';
import { FeeScheduleTierRibbonComponent } from './fees/fee-schedule-tier-ribbon/fee-schedule-tier-ribbon.component';
import { FeeScheduleTierComponent } from './fees/fee-schedule-tier/fee-schedule-tier.component';
import { FeeScheduleComponent } from './fees/fee-schedule/fee-schedule.component';
import { FeesComponent } from './fees/fees.component';
import { EditGoalRibbonComponent } from './goal/edit-goal-ribbon/edit-goal-ribbon.component';
import { EditGoalComponent } from './goal/edit-goal/edit-goal.component';
import { MonteCarloAnalysisDrawerComponent } from './goal/monte-carlo-analysis-drawer/monte-carlo-analysis-drawer';
import { MonteCarloChartComponent } from './goal/monte-carlo-analysis-drawer/monte-carlo-chart/monte-carlo-chart';
import { ImplementModalComponent } from './implement/implement-modal.component';
import { CurrencySuffixPipe } from './pipes/currency-suffix.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { PresentationChecklistComponent } from './presentation-checklist/presentation-checklist.component';
import { CoverPageComponent } from './presentation-checklist/sections/cover-page/cover-page-component';
import { HtmlFormElementsComponent } from './presentation-checklist/sections/cover-page/html-form-elements/html-form-elements.component';
import { AnalyticsComponent } from './presentation-checklist/sections/ips-section/analytics/analytics.component';
import { IpsSectionComponent } from './presentation-checklist/sections/ips-section/ips-section.component';
import { ReportOptionsComponent } from './presentation-checklist/sections/ips-section/report-options/report-options.component';
import { ReportingLevelsComponent } from './presentation-checklist/sections/ips-section/reporting-levels/reporting-levels.component';
import { CostOverviewComponent } from './presentation-checklist/sections/proposal-section/cost-overview/cost-overview.component';
import { ProposalSectionComponent } from './presentation-checklist/sections/proposal-section/proposal-section.component';
import { AccountChecklistComponent } from './proposal/account-checklist/component/account-checklist.component';
import { ProposalAccountOptionSelectionComponent } from './proposal/proposal-account-option-selection/proposal-account-option-selection.component';
import { ProposalAccountOwnerComponent } from './proposal/proposal-account-owner/proposal-account-owner.component';
import { ProposalAccountTypesComponent } from './proposal/proposal-account-types/proposal-account-types.component';
import { ProposalAddClientComponent } from './proposal/proposal-add-client/proposal-add-client.component';
import { ProposalAdvisorsFormComponent } from './proposal/proposal-advisors-form/proposal-advisors-form.component';
import { ProposalClientsFormComponent } from './proposal/proposal-client-form/proposal-clients-form.component';
import { ProposalGoalComponent } from './proposal/proposal-goal/proposal-goal.component';
import { ProposalScenarioComponent } from './proposal/proposal-scenario/proposal-scenario.component';
import { SearchOwnerFormComponent } from './proposal/proposal-search-owner-form/proposal-search-owner-form.component';
import { ProposalComponent } from './proposal/proposal.component';
import { QuestionnaireFooterComponent } from './risk-questionnaire/questionnaire-footer/questionnaire-footer.component';
import { QuestionnaireMainComponent } from './risk-questionnaire/questionnaire-main/questionnaire-main.component';
import { QuestionnaireQuestionSelectionComponent } from './risk-questionnaire/questionnaire-question-selection/questionnaire-question-selection.component';
import { QuestionnaireQuestionComponent } from './risk-questionnaire/questionnaire-question/questionnaire-question.component';
import { RiskSelectionComponent } from './risk-selection/risk-selection.component';
import { AccountTypeOptionsService } from './service/account-type-options.service';
import { FeeScheduleGroupService } from './service/fee-schedule-group.service';
import { HttpErrorInterceptorService } from './service/http/http-error-interceptor.service';
import { HttpService } from './service/http/http.service';
import { PresentationChecklistService } from './service/presentation-checklist.service';
import { PresentationSettingService } from './service/presentation-setting.service';
import { ProposalAccountStrategyService } from './service/proposal-account-strategy.service';
import { ProposalService } from './service/proposal.service';
import { QuestionnaireService } from './service/questionnaire.service';
import { ConfigurationService } from './service/system/configuration.service';
import { UserProfileService } from './service/user-profile.service';
import { ExploreStrategyComponent } from './strategy-selection/explore-strategy/explore-strategy.component';
import { FilterStrategyRiskRankComponent } from './strategy-selection/explore-strategy/filter-strategy-risk-rank/filter-strategy-risk-rank.component';
import { PreviouslyUsedStrategyComponent } from './strategy-selection/previously-used-strategy/previously-used-strategy.component';
import { StrategyAllocationsComponent } from './strategy-selection/strategy-allocations/strategy-allocations.component';
import { StrategyDrawerComponent } from './strategy-selection/strategy-drawer/strategy-drawer.component';
import { StrategyDropdownComponent } from './strategy-selection/strategy-dropdown/strategy-dropdown.component';
import { StrategyRibbonComponent } from './strategy-selection/strategy-ribbon/strategy-ribbon.component';
import { StrategySelectionComponent } from './strategy-selection/strategy-selection.component';
import { NumberEvaluator } from './utilities/number-evaluator.utility';
import { WipChecklistAccountTypesComponent } from './wip-checklist/wip-checklist-account-types/wip-checklist-account-types.component';
import { WipChecklistAccountsComponent } from './wip-checklist/wip-checklist-accounts/wip-checklist-accounts.component';
import { WipChecklistActionButtonComponent } from './wip-checklist/wip-checklist-action-button/wip-checklist-action-button.component';
import { WipChecklistActionsComponent } from './wip-checklist/wip-checklist-actions/wip-checklist-actions.component';
import { WipChecklistDetailsComponent } from './wip-checklist/wip-checklist-details/wip-checklist-details.component';
import { WipChecklistFeesComponent } from './wip-checklist/wip-checklist-fees/wip-checklist-fees.component';
import { WipChecklistFooterComponent } from './wip-checklist/wip-checklist-footer/wip-checklist-footer.component';
import { WipChecklistGoalsComponent } from './wip-checklist/wip-checklist-goals/wip-checklist-goals.component';
import { WipChecklistLoadingSpinnerComponent } from './wip-checklist/wip-checklist-loading-spinner/wip-checklist-loading-spinner.component';
import { WipChecklistNonModelsComponent } from './wip-checklist/wip-checklist-non-models/wip-checklist-non-models.component';
import { WipChecklistSpacerComponent } from './wip-checklist/wip-checklist-spacer/wip-checklist-spacer.component';
import { WipChecklistStatusIndicatorComponent } from './wip-checklist/wip-checklist-status-indicator/wip-checklist-status-indicator.component';
import { WipChecklistComponent } from './wip-checklist/wip-checklist.component';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        CommonModule,
        ButtonsModule,
        PopoverModule,
        TooltipModule,
        DragDropModule,
        RouterModule,
        SeiCommonSwpComponentsLibraryModule.forRoot(),
        SeiCommonComponentsLibraryModule.forRoot(),
        ClientAccountAdministrationLibUxModule.forRoot(),
        ColorPickerModule,
        SeiCommonWijmoLibraryModule.forRoot(),
        SeiCommonSwpWijmoLibraryModule.forRoot(),
        IasApplicationsLibUxModule.forRoot(),
        OrderByPipe,
        ProposalTaxTransitionAnalysisComponent,
        ProposalTaxTransitionAnalysisCurrentAccountComponent,
        ProposalTaxTransitionAnalysisFormComponent
    ],
    declarations: [
        // Common Components
        RightHandDialogFooterComponent,
        RightHandDialogComponent,
        PagingNextPreviousButtonComponent,
        AccountOwnerDropdownComponent,
        AccountAdvisorsDropdownComponent,
        ToggleButtonComponent,
        OptionSelectionComponent,
        GenericErrorNotificationComponent,
        RiskWidgetComponent,
        RiskSummaryWidgetComponent,
        AlertPopoverComponent,
        CurrencySuffixPipe,
        ToggleSeiWizardNavComponent,
        ToggleIncludeExcludeComponent,
        DocumentSelectionComponent,
        SettingsNodeComponent,
        EntitySearchComponent,
        SettingsElementComponent,
        SettingsDropdownComponent,
        ImageModalComponent,
        FileUploadModalFooterComponent,
        FileUploadModalPanelComponent,
        FileDropDirective,
        SelectFirmAdvisorComponent,
        HeadsUpDisplayComponent,
        FeeStrategySummaryComponent,
        FirmAdvisorSearchComponent,
        DisplayMonetaryAmountComponent,
        ActionToolTipPopoverComponent,
        InlineEditComponent,
        NavigatorComponent,
        AltSourceImageComponent,
        AdvisorDropdownComponent,
        // Proposal Components
        ProposalComponent,
        ProposalScenarioComponent,
        ProposalClientsFormComponent,
        ProposalAccountTypesComponent,
        ProposalAccountOwnerComponent,
        SearchOwnerFormComponent,
        ProposalAccountOptionSelectionComponent,
        ProposalAddClientComponent,
        ProposalGoalComponent,
        ProposalAdvisorsFormComponent,
        // Risk Questionnaire Components
        QuestionnaireFooterComponent,
        QuestionnaireMainComponent,
        QuestionnaireQuestionSelectionComponent,
        QuestionnaireQuestionComponent,
        // CheckList Components
        WipChecklistComponent,
        WipChecklistFooterComponent,
        WipChecklistDetailsComponent,
        WipChecklistActionsComponent,
        WipChecklistActionButtonComponent,
        WipChecklistAccountTypesComponent,
        WipChecklistAccountsComponent,
        WipChecklistGoalsComponent,
        WipChecklistFeesComponent,
        WipChecklistSpacerComponent,
        WipChecklistStatusIndicatorComponent,
        WipChecklistLoadingSpinnerComponent,
        WipChecklistNonModelsComponent,
        // Strategy Selection Components
        StrategySelectionComponent,
        RiskSelectionComponent,
        StrategyRibbonComponent,
        StrategyDrawerComponent,
        StrategyAllocationsComponent,
        StrategyMenuComponent,
        StrategyDropdownComponent,
        ExploreStrategyComponent,
        FilterStrategyRiskRankComponent,
        PreviouslyUsedStrategyComponent,
        // Presentation Components
        PresentationChecklistComponent,
        ProposalSectionComponent,
        ReportingLevelsComponent,
        ReportOptionsComponent,
        AnalyticsComponent,
        IpsSectionComponent,
        CoverPageComponent,
        CostOverviewComponent,
        HtmlFormElementsComponent,
        // Dashboard
        DashboardTableComponent,
        // FeeSchedule Components
        FeesComponent,
        FeeScheduleComponent,
        FeeScheduleAccountComponent,
        FeeScheduleAccountDetailComponent,
        FeeScheduleDropContainerComponent,
        FeeScheduleFeeRateComponent,
        FeeScheduleRibbonComponent,
        FeeScheduleFlatFeeComponent,
        FeeScheduleFirmComponent,
        FeeScheduleTierComponent,
        FeeScheduleTierRibbonComponent,
        FeeBreakdownTooltipComponent,
        // Goals
        EditGoalComponent,
        EditGoalRibbonComponent,
        // Implement
        ImplementModalComponent,
        FileMultipleUploadComponent,
        AccountChecklistComponent,
        ReactivateModalComponent,
        PercentFormatPipe,
        MonteCarloAnalysisDrawerComponent,
        MonteCarloChartComponent
    ],
    exports: [
        ProposalComponent,
        ProposalScenarioComponent,
        ProposalClientsFormComponent,
        ProposalAdvisorsFormComponent,
        RightHandDialogComponent,
        PagingNextPreviousButtonComponent,
        WipChecklistComponent,
        WipChecklistFooterComponent,
        StrategySelectionComponent,
        RiskSelectionComponent,
        ToggleButtonComponent,
        AlertPopoverComponent,
        ToggleSeiWizardNavComponent,
        ToggleIncludeExcludeComponent,
        DocumentSelectionComponent,
        PresentationChecklistComponent,
        SettingsNodeComponent,
        SettingsElementComponent,
        GenericErrorNotificationComponent,
        DashboardTableComponent,
        SelectFirmAdvisorComponent,
        RiskWidgetComponent,
        StrategyMenuComponent,
        RiskSummaryWidgetComponent,
        FeesComponent,
        FeeScheduleComponent,
        StrategyMenuComponent,
        FeeScheduleAccountComponent,
        FeeScheduleAccountDetailComponent,
        FeeScheduleDropContainerComponent,
        FeeScheduleFeeRateComponent,
        FeeScheduleRibbonComponent,
        HeadsUpDisplayComponent,
        FeeStrategySummaryComponent,
        OptionSelectionComponent,
        FeeScheduleFlatFeeComponent,
        FeeScheduleFirmComponent,
        FeeScheduleTierComponent,
        FeeScheduleTierRibbonComponent,
        ActionToolTipPopoverComponent,
        InlineEditComponent,
        NavigatorComponent,
        EditGoalComponent,
        EditGoalRibbonComponent,
        SeiCommonSwpComponentsLibraryModule,
        AltSourceImageComponent,
        ImplementModalComponent,
        FileMultipleUploadComponent,
        IasApplicationsLibUxModule,
        SearchOwnerFormComponent,
        FeeBreakdownTooltipComponent,
        ReactivateModalComponent,
        PercentFormatPipe,
        WipChecklistNonModelsComponent,
        MonteCarloAnalysisDrawerComponent,
        MonteCarloChartComponent
    ],
    providers: [
        ConfigurationService,
        ProposalService,
        AccountTypeOptionsService,
        QuestionnaireService,
        PresentationSettingService,
        ProposalAccountStrategyService,
        FeeScheduleGroupService,
        CurrencyPipe,
        DatePipe,
        UserProfileService,
        DisplayMonetaryAmountPipe,
        DisplayPercentPipe,
        NumberEvaluator,
        PresentationChecklistService,
        PercentFormatPipe
    ]
})
export class CarUxLibModule {
    /**
     * Use this method in your root module to provide the ProposalService
     * @returns ModuleWithProviders
     */

    constructor() {
        /* set wijmo licensekey */
        WijmoLicense.setLicenseKey();
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static forRoot(): ModuleWithProviders<CarUxLibModule> {
        return {
            ngModule: CarUxLibModule,
            providers: [
                ProposalService,
                AccountTypeOptionsService,
                HttpService,
                SpinnerService,
                PresentationSettingService,
                FeeScheduleGroupService,
                ConfigurationService,
                {
                    provide: 'env',
                    useValue: ENVIRONMENT
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: HttpErrorInterceptorService,
                    multi: true
                },
                UserProfileService,
                PresentationChecklistService
            ]
        };
    }
}
