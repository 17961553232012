/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { TooltipConstants } from '@CarModels/constants';
import { ExistingAccountData } from '@CarModels/existing-accounts';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonSize, DisplayAmountOptions, DisplayNullOptions, DisplayNumericScaleSuffix, NumericScale } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager, SubpageheaderModel } from '@sei/common-swp-components-lib-ux';
import { InvestmentTypeId, Portfolio } from '@sei/ias-applications-lib-ux';
import * as _ from 'lodash';
import { RiskToggleOptions, WipAccountStatus, WipTagNames } from '../../model/enums';
import { Account, Allocation, MdcSnapshot, Proposal, Strategy, StrategySubstitutionsList, WipCheckList, WipCheckListDisplayModel } from '../../model/proposal';
import { ProposalAccountService } from '../../service/proposal-account.service';
import { ProposalService } from '../../service/proposal.service';

@Component({
    selector: 'car-wip-checklist-accounts',
    templateUrl: './wip-checklist-accounts.component.html',
    styleUrls: ['./wip-checklist-accounts.component.scss']
})
export class WipChecklistAccountsComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public proposalId: number;

    @Input()
    public checkListId: number;

    @Input()
    public wipCheckList: WipCheckList;

    @Input()
    public sectionType: string;

    @Input()
    public isLast: boolean = false;

    @Input()
    public showExisting: boolean = false;

    public wipTagNames: typeof WipTagNames = WipTagNames;
    public accountStatus: typeof WipAccountStatus = WipAccountStatus;
    public account: Account;
    public buttonDisabled: boolean = false;
    public showModelInvestmentDetails: boolean = true;
    public showNonModelInvestmentDetails: boolean = true;
    public isPortfolioAssetDataLoading: boolean = false;
    public readonly SHOW_MORE_MESSAGE: string = 'Use the arrow to expand the section details';
    public nonModelPageHeaderProperties: SubpageheaderModel = { title: 'Non Model Investments', switcherAvailable: false };
    public modelPageHeaderProperties: SubpageheaderModel = { title: 'Model Investments', switcherAvailable: false };
    public selectedAccountRiskValue: string;
    public displayNumericScaleSuffix: DisplayNumericScaleSuffix = {
        scale: NumericScale.NULL,
        displayDecimals: 2
    };
    public roundFractionalNumber: boolean = false;
    public amountOptions: DisplayAmountOptions = {
        nullDisplay: DisplayNullOptions.ZERO,
        roundFractionalAmount: false
    };
    public isSubstitutionEnabled: boolean = false;
    public listOfSubstitutionsByStrategy: WipCheckListDisplayModel[] = [];
    public buttonSize: ButtonSize = ButtonSize.ExtraSmall;
    public accountLengthWarning: string = '';
    public removeFundsFromExistingAccount: boolean = false;
    private existingAccountData: ExistingAccountData;
    private proposal: Proposal;

    constructor(private proposalService: ProposalService,
        public proposalAccountService: ProposalAccountService,
        private featureFlagService: FeatureFlagService,
        private router: Router) {
        super('WipChecklistAccountsComponent');
    }

    ngOnInit(): void {
        this.accountLengthWarning = TooltipConstants.ACCOUNT_NAME_OVER_MAX_LENGTH;
        this.subscriptions.push(this.proposalService.currentProposal.subscribe((proposal: Proposal) => {
            this.proposal = proposal;
            this.account =
                _.cloneDeep(proposal?.scenarios[0]?.accounts
                    .find((scenarioAccount: Account) => scenarioAccount.id === Number(this.wipCheckList.mdcSnapShot.id)));
            if (this.account) {
                this.account.isAccountNameOverMaximumLength =
                    this.proposalAccountService.buildAccountNameAndCheckLength(this.account);
                this.buttonDisabled = !this.account?.investmentProgramId;
                this.selectedAccountRiskValue = this.proposalAccountService.mapAccountRiskScoreToRiskName(this.account);
                if (this.account?.strategies && this.account?.strategies?.length > 0) {
                    this.listOfSubstitutionsByStrategy = [];
                    this.account.strategies.forEach((strategy) => {
                        // For UMAs
                        if (strategy?.strategyTypeId === InvestmentTypeId.Custom) {
                            const strategySubstitutionsList: StrategySubstitutionsList[] = [];
                            strategy?.customStrategy?.allocations?.forEach((allocation: Allocation) => {
                                let strategySubstitutions: string = '';
                                if (allocation?.selectedSubstitutions && allocation?.selectedSubstitutions?.length > 0) {
                                    allocation.selectedSubstitutions?.forEach((substitution) => {
                                        strategySubstitutions = strategySubstitutions?.concat(' ',
                                            substitution?.name, ' - ', substitution?.displayName?.trim(), ' |');
                                    });
                                    strategySubstitutionsList.push({
                                        modelName: allocation?.allocationDescription,
                                        substitutionDetail: this.trimTrailingCharacterForDisplay(strategySubstitutions)
                                    });
                                }
                            });
                            this.listOfSubstitutionsByStrategy.push({
                                strategyName: strategy.strategyName,
                                strategyAmount: strategy.amount,
                                strategySubstitutions: strategySubstitutionsList,
                                isStandaloneStrategy: false,
                                isSubstitutionDetailsClicked: false
                            });
                        } else {
                            // For Strategy
                            const strategySubstitutionsList: StrategySubstitutionsList[] = [];
                            if (strategy?.selectedSubstitutions && strategy?.selectedSubstitutions?.length > 0) {
                                let strategySubstitutions: string = '';
                                strategy?.selectedSubstitutions?.forEach((substitution) => {
                                    strategySubstitutions =
                                        strategySubstitutions.concat(' ', substitution?.name, ' - ',
                                            substitution?.displayName?.trim(), ' |');
                                });
                                strategySubstitutionsList.push({
                                    modelName: strategy.strategyName,
                                    substitutionDetail: this.trimTrailingCharacterForDisplay(strategySubstitutions)
                                });
                            }
                            this.listOfSubstitutionsByStrategy.push({
                                strategyName: strategy.strategyName,
                                strategyAmount: strategy.amount,
                                strategySubstitutions: strategySubstitutionsList,
                                isStandaloneStrategy: true,
                                isSubstitutionDetailsClicked: false
                            });
                        }
                    });
                } else {
                    this.listOfSubstitutionsByStrategy = [];
                }
            }
        }));
        this.isSubstitutionEnabled = this.featureFlagService.isSubstitutionsFeatureFlagEnabled();
        this.proposalAccountService.isPortfolioCallInProgress.subscribe((portfolioLoadingStatus: boolean) => {
            this.isPortfolioAssetDataLoading = portfolioLoadingStatus;
        });
        if (this.showExisting && this.account) {
            Array.from(this.proposalService.getExistingAccountsPickListFromCurrentProposal().values())
                ?.forEach((accountList: ExistingAccountData[]) => {
                    const snapshotIndex: number = accountList
                        .findIndex((account: ExistingAccountData) => account.accountId === this.account.currentAccountId);
                    if (snapshotIndex >= 0) {
                        this.existingAccountData = accountList[snapshotIndex];
                    }
                });
        }
    }

    private trimTrailingCharacterForDisplay(strategySubstitutions: string): string {
        return strategySubstitutions.slice(0, -2);
    }

    public onClickPersonalize(listOfSubstitutionsByStrategy: WipCheckListDisplayModel[], index: number, click: boolean): void {
        this.listOfSubstitutionsByStrategy[index].isSubstitutionDetailsClicked = !click;
    }

    public calculateAccountBalanceWithNonModel(account?: Account): number {
        if (account?.portfolios?.length > 0) {
            return this.calculateAccountModelBalance(account) +
                this.proposalAccountService.calculateNonModelInvestmentTotal(account?.portfolios);
        }
        // when removing non model feature flag, return balance + this.calculateNonModelInvestmentTotal(portfolios)
        return this.calculateAccountModelBalance(account);
    }

    public calculateAccountModelBalance(account: Account): number {
        let balance: number = 0;
        account?.strategies?.length > 0 ?
            account.strategies.forEach((strategy: Strategy) => {
                const amount: number = strategy.amount ? Number(strategy.amount) : 0;
                balance += amount;
            }) :
            balance = 0;
        return Number(balance);
    }

    public toggleShowModelInvestmentDetails() {
        this.showModelInvestmentDetails = !this.showModelInvestmentDetails;
    }

    public checkIfRiskStatusIsIncomplete(): boolean {
        return this.selectedAccountRiskValue === RiskToggleOptions.Incomplete;
    }

    public checkIfAccountHasSelectedRiskMethod(): boolean {
        return this.proposalAccountService.doesAccountHaveSelectedRiskMethod(this.account);
    }


    public getMdcSnapshot(): MdcSnapshot {
        const accountHasFundedStrategies: boolean = this.account?.strategies?.length > 0 &&
            this.account?.strategies.every((strategy: Strategy) => strategy?.amount > 0);
        const completed: WipAccountStatus = accountHasFundedStrategies ?
            WipAccountStatus.Complete : WipAccountStatus.Incomplete;
        return {
            completed
        };
    }

    public onModifyInvestmentsClicked(): void {
        this.account.sweepModelElection = {
            isFdic: this.existingAccountData?.fdic,
            currencySweepModelAllocDescription: this.existingAccountData?.sweepModelDescription,
            platformSweepModelId: this.existingAccountData?.sweepModelId
        };
        this.account.platformFeeCapDetails = this.existingAccountData?.platformFeeCapDetails;
        const matchingAccountIndex: number =
            this.proposal?.scenarios[0]?.accounts?.findIndex((account) => account?.currentAccountId === this.account?.currentAccountId);
        if (matchingAccountIndex >= 0) {
            this.proposal.scenarios[0].accounts[matchingAccountIndex] = this.account;
        }
        this.proposalService.changedProposal(_.cloneDeep(this.proposal));
        this.router.navigate(
            ['Proposal', 'WIP', this.proposal.id, 'Scenarios', this.proposal.scenarios[0].id, 'Accounts', this.account.id]);
    }

    public getAccountMarketValue(): number {
        let accountMarketValue: number = 0;
        if (this.existingAccountData) {
            accountMarketValue = this.existingAccountData?.actualCurrentMarketValue;
        }
        return accountMarketValue;
    }

    public getNewFunds(): number {
        const modelDeltaValue: number = this.getModelInvestmentValue() - this.existingAccountData?.modelMarketValue;
        const existingNonModelTotal: number = this.calculatePortfolioTotal(this.existingAccountData?.existingNonModelPortfolios);
        const nonDeletedPortfolios: Portfolio[] = this.proposalAccountService.filterOutDeletedPortfolios(this.account?.portfolios);
        const proposedNonModelTotal: number = this.calculatePortfolioTotal(nonDeletedPortfolios);
        const nonModelDeltaValue: number = existingNonModelTotal - proposedNonModelTotal;
        this.checkIfRemoveOrAdd(modelDeltaValue, nonModelDeltaValue);
        return Math.abs(modelDeltaValue - nonModelDeltaValue);
    }

    public checkIfRemoveOrAdd(modelDeltaValue: number, nonModelDeltaValue: number): void {
        this.removeFundsFromExistingAccount = modelDeltaValue - nonModelDeltaValue < 0;
    }

    public getModelInvestmentValue(): number {
        let currentModelInvestmentsValue: number = 0;
        this.account?.strategies?.forEach((strategy) => {
            currentModelInvestmentsValue = currentModelInvestmentsValue + strategy.amount;
        });
        return currentModelInvestmentsValue;
    }

    public getTotalProposedAmount(): number {
        return this.removeFundsFromExistingAccount ?
            this.getAccountMarketValue() - this.getNewFunds() :
            this.getAccountMarketValue() + this.getNewFunds();
    }

    private calculatePortfolioTotal(portfolios: Portfolio[]): number {
        const filteredPortfolios: Portfolio[] = this.filterOutDeletedPortfolios(portfolios);
        return this.proposalAccountService.calculateNonModelInvestmentTotal(filteredPortfolios);
    }

    public filterOutDeletedPortfolios(portfolios: Portfolio[]): Portfolio[] {
        if (this.account?.currentAccountId) {
            return this.proposalAccountService.filterOutDeletedPortfolios(portfolios);
        }
        return this.account?.portfolios;
    }

}
