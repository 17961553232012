<ng-template #recursiveList
             let-list>
    <ng-container>
        <div cdkDropList
             [cdkDropListData]="proposalSection.sections"
             (cdkDropListDropped)="dropItem($event)"
             [cdkDropListConnectedTo]="getConnectedList()">
            <div cdkDrag
                 *ngFor="let section of list | orderBy: 'sortOrder';"
                 [cdkDragData]="section"
                 [ngSwitch]="section.name">
                <div class="row toggle-row"
                     *ngIf="!section.hidden">
                    <div class="col-xs-9 toggle-row-left"
                         [class._indent2]="section.level === 2"
                         [class._indent4]="section.level === 3">
                        <span cdkDragHandle
                            *ngIf="!section.pinned"
                            class="wip-menu-grabber">
                            <i class="fa fas fa-grip-vertical"></i>
                        </span>
                        <span *ngIf="section.pinned" class="wip-menu-grabber">&nbsp;</span>
                        <p>{{section.name}}</p>
                    </div>
                    <div class="col-xs-3 toggle-row-center"
                         *ngIf="section.level === 2">
                        <sei-icon-toggle name="icon-toggle-{{section.id}}"
                                         [(ngModel)]="section.include"
                                         (ngModelChange)="modelChanged(proposalSection)"
                                         [trueIcon]="trueIcon"
                                         [falseIcon]="falseIcon"
                                         [iconClass]="iconClass"
                                         [iconSize]="iconSize"
                                         [offIsGrey]="offIsGrey"
                                         [disabled]="disabled">
                        </sei-icon-toggle>
                    </div>
                    <div class="col-xs-3"
                         *ngIf="section?.inputProperty?.elementType === elementTypes.DropDown">
                         <sei-car-settings-dropdown id="dropdown-{{section.id}}" 
                         [dropDownContent]="getDropDownSource(section)"
                         [(ngModel)]="section.inputProperty.value"
                         [selectWidth]="'55'"
                         (ngModelChange)="onSectionValueChange(proposalSection)">
                        </sei-car-settings-dropdown>
                    </div>
                    <div class="col-xs-2"
                         *ngIf="section.statementSectionId === targetAmountSectionId">
                         <input type="text"
                               class="form-control"
                               id="textBox-{{section.id}}"
                               ngDefaultControl
                               seiEditMonetaryAmount
                               [amountOptions]="amountOptions"
                               [actualCurrency]="this.currencyTypes.USD"
                               [(ngModel)]="section.inputProperty.value"
                               (ngModelChange)="onSectionValueChange(proposalSection)"
                               maxlength="15"/>
                    </div>
                    <div class="col-xs-2"
                         *ngIf="section.statementSectionId === inflationRateSectionId">
                         <input type="text"
                               placeholder="%"
                               class="form-control"
                               id="textBox-{{section.id}}"
                               ngDefaultControl
                               seiEditPercent
                               [percentOptions]="amountOptions"
                               [(ngModel)]="section.inputProperty.value"
                               (ngModelChange)="onSectionValueChange(proposalSection)"
                               maxlength="3" />
                    </div>
                </div>
                <ng-container *ngIf="section.sections.length > 0">
                    <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: section.sections }"></ng-container>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-template>
<ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: proposalSection.sections }"></ng-container>
