<car-right-hand-dialog [footerFunction]="footerFunction">
    <car-generic-error-notification *ngIf="!getHoldingsCallFailed"></car-generic-error-notification>
    <div *ngIf="!isExistingAccountsCallInProgress; else existingAccountsLoading">
        <div class="header-padding fixed">
            <div class="row">
                <div class="col-xs-12">
                    <h1>Select Current Accounts</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <sei-car-existing-accounts-pick-list-notification *ngIf="!getHoldingsCallFailed"
                                                                      [totalNumberOfExistingAccounts]="totalNumberOfExistingAccounts"
                                                                      [totalNumberOfProposedAccounts]="totalNumberOfProposedAccounts"
                                                                      [isAccountTotalBelowThreshold]="isAccountTotalBelowThreshold">
                    </sei-car-existing-accounts-pick-list-notification>
                    <sei-alert *ngIf="getHoldingsCallFailed"
                               [showCloseIcon]="false"
                               [type]="typeWarning">
                        <div [innerHTML]="getHoldingsCallFailedWarning"></div>
                    </sei-alert>
                </div>
            </div>
        </div>
        <sei-car-existing-accounts-owner-section *ngFor="let existingAccountInfo of existingAccountsNotAddedToProposal | keyvalue"
                                                 [client]="getClientFromEntityId(proposal?.clients, existingAccountInfo.key)"
                                                 [existingAccountsOwnedByClient]="existingAccountInfo"
                                                 [proposal]="proposal"
                                                 [selectedExistingAccounts]="selectedExistingAccounts">
        </sei-car-existing-accounts-owner-section>
    </div>
    <sei-car-warning-modal #unselectedAccountsModal
                           [message]="unselectedAccountsModalMessage"
                           [modalTitle]="'Removed Account'"
                           [showModal]="showUnselectedAccountsModal"
                           (continueClicked)="onUnselectedAccountsModalContinue()"
                           (cancelClicked)="onUnselectedAccountsModalCancel()"></sei-car-warning-modal>
    <sei-car-existing-accounts-portfolio-mapping *ngIf="selectedExistingAccounts.length > 0 && addSelectedAccountClicked && showMappingModal"
                                                 [showModal]="showMappingModal"
                                                 [selectedExistingAccounts]="getNewCurrentSelectedAccounts()"
                                                 (cancelClicked)="resetMappingSemaphoreValues()">

    </sei-car-existing-accounts-portfolio-mapping>
</car-right-hand-dialog>

<ng-template #existingAccountsLoading>
    <div class="loading-spinner">
        <sei-loading [showLoadingIcon]="!isExistingAccountsCallComplete"
                     [multiplier]="3">
        </sei-loading>
    </div>
</ng-template>
