/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { ExistingAccountData } from '@CarModels/existing-accounts';
import { ExistingAccountsService } from '@CarServices/existing-accounts/existing-accounts.service';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { SpinnerService } from '@CarServices/system/spinner.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Account, AccountOwner, Client, CommandText, DelegationService, EntityType, GenericErrorService, Proposal, ProposalAccountService, ProposalService, RightHandFooterFunctions, Scenario, WipCheckList } from '@sei/advisor-client-review-proposal-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sei-car-proposal-all-accounts',
    templateUrl: './all-accounts.component.html',
    styleUrls: ['./all-accounts.component.scss']
})
export class AllAccountsComponent extends ParentComponentSubscriptionManager implements OnInit {

    public proposal: Proposal;
    public proposalBackup: Proposal;
    public scenario: Scenario;
    public existingAccountsAddedToProposal: Account[] = [];
    public existingAccounts: Map<number, ExistingAccountData[]>;
    public isAddProposedAccountsButtonDisabled: boolean = false;
    public selectedExistingAccounts: ExistingAccountData[] = [];
    public footerFunction: string = RightHandFooterFunctions.EditProposedAccounts;
    public isExistingAccountsFeatureEnabled: boolean = false;
    public getHoldingsCallFailed: boolean = false;
    constructor(private router: Router,
        private existingAccountsService: ExistingAccountsService,
        private genericErrorService: GenericErrorService,
        private proposalService: ProposalService,
        private delegationService: DelegationService,
        private proposalAccountService: ProposalAccountService,
        private featureFlagService: FeatureFlagService,
        private spinnerService: SpinnerService) {
        super('AllAccountsComponent');
    }

    ngOnInit(): void {
        this.proposalBackup = _.cloneDeep(this.proposalService.getCurrentProposal());
        this.isExistingAccountsFeatureEnabled = this.featureFlagService.checkExistingAccountsSectionEnabled();
        const proposalSubscription: Subscription = this.proposalService.currentProposal.subscribe({
            next: (proposal: Proposal): void => {
                if (proposal) {
                    this.proposal = proposal;
                    this.scenario = proposal?.scenarios[0];
                    const isProposalValid: boolean = this.proposalService.validateProposal(this.proposal, this.proposal.scenarios[0].id);
                    isProposalValid ? this.delegationService.publish(CommandText.EnableSaveAndExitButton) :
                        this.delegationService.publish(CommandText.DisableSaveAndExitButton);
                    if (!this.featureFlagService.checkExistingAccountsSectionEnabled() && !this.proposal.scenarios[0]?.accounts.length) {
                        this.proposalAccountService.addNewAccountToProposal(this.proposal);
                    }
                    if (this.proposal?.scenarios[0]?.existingAccountsForPickList?.size) {
                        this.existingAccounts = this.proposal?.scenarios[0]?.existingAccountsForPickList;
                        this.setExistingAccountsFromProposal();
                    }
                }
            },
            error: (error) => {
                this.genericErrorService.setGenericError({ code: '', description: `${error.message}` });
            }
        });
        this.subscriptions.push(proposalSubscription);

        const selectedExistingAccountsSubscription: Subscription =
            this.existingAccountsService.existingAccountsToBeAddedToProposal.subscribe((selectedExisting: ExistingAccountData[]) => {
                this.selectedExistingAccounts = selectedExisting;
            });
        this.subscriptions.push(selectedExistingAccountsSubscription);

        const delegationSubscription: Subscription = this.delegationService.refresh().subscribe({
            next: (command: string): void => {
                switch (command) {
                    case CommandText.EnableSaveAndExitButton:
                        this.isAddProposedAccountsButtonDisabled = false;
                        break;
                    case CommandText.DisableSaveAndExitButton:
                        this.isAddProposedAccountsButtonDisabled = true;
                        break;
                    case CommandText.SaveEditProposal:
                        if (this.isExistingAccountsFeatureEnabled) {
                            this.proposalService.fixProposalForSave(this.proposal);
                            this.proposal.scenarios[0].accounts = this.proposal.scenarios[0].accounts
                                .filter((account: Account) =>
                                    !account.currentAccountId ||
                                    this.selectedExistingAccounts?.some((existingAccount: ExistingAccountData) =>
                                        existingAccount.accountId === account.currentAccountId));
                            this.existingAccountsService.resetTemporaryPortfolioIdsForNewCurrentAccounts(this.proposal);
                            this.proposalAccountService.removeNonModelTotalsFromAccountBalance(this.proposal?.scenarios[0]?.accounts);
                            this.spinnerService.start();
                            this.subscriptions.push(
                                this.proposalService.updateProposalScenario(this.proposal, this.scenario.id)
                                    .subscribe((responseProposal: Proposal) => {
                                        responseProposal.scenarios.forEach((scenario: Scenario) => {
                                            scenario?.accounts.forEach((account: Account) => {
                                                if (account?.owners.every((owner: AccountOwner) => owner?.ownerOrder)) {
                                                    account.owners.sort((a: AccountOwner, b: AccountOwner) => a.ownerOrder - b.ownerOrder);
                                                }
                                            });
                                        });
                                        this.genericErrorService.clearGenericError();
                                        this.proposalService.changedProposal(responseProposal);
                                        this.proposalAccountService.updateWipChecklistsForCurrentAccounts(responseProposal);
                                        this.subscriptions.push(
                                            this.proposalService.updateProposalWipCheckList(responseProposal.wipCheckList)
                                                .subscribe((updatedWipChecklist: WipCheckList[]) => {
                                                    responseProposal.wipCheckList = updatedWipChecklist;
                                                    this.delegationService.publish(CommandText.UpdateWipCheckList);
                                                    this.proposalService.proposalBackup = undefined;
                                                    this.spinnerService.stop();
                                                    this.delegationService.publish(CommandText.LoadingStopped);
                                                    this.existingAccountsService.resetSelections();
                                                    this.router.navigate([`/Proposal/WIP/${this.proposal.id}`]);
                                                }, (error) => {
                                                    this.genericErrorService.setGenericError({
                                                        code: '',
                                                        description: 'Invalid Entry'
                                                    });
                                                    this.spinnerService.stop();
                                                    this.delegationService.publish(CommandText.LoadingStopped);
                                                }));
                                    }, (error) => {
                                        this.genericErrorService.setGenericError({
                                            code: '',
                                            description: 'Invalid Entry'
                                        });
                                        this.spinnerService.stop();
                                        this.delegationService.publish(CommandText.LoadingStopped);
                                    }));
                        }
                        break;
                    case CommandText.AccountsFlyoutCancel:
                        this.existingAccountsService.deselectAllExistingAccounts();
                        this.proposal = _.cloneDeep(this.proposalBackup);
                        this.proposal.scenarios[0].accounts = this.removeAnyUnsavedAccounts(this.proposal?.scenarios[0]?.accounts);
                        this.proposalService.changedProposal(this.proposal);
                        this.delegationService.publish(CommandText.UpdateWipCheckList);
                        this.router.navigate([`/Proposal/WIP/${this.proposal.id}`]);
                        break;
                    default:
                        break;
                }
            },
            error: (error) => {
                this.genericErrorService.setGenericError({ code: '', description: `${error.message}` });
            }
        });
        this.subscriptions.push(delegationSubscription);

        const getHoldingsFailureStatus: Subscription =
            this.existingAccountsService.existingAccountCallFailed.subscribe((getHoldingsStatus: boolean): void => {
                this.getHoldingsCallFailed = getHoldingsStatus;
            });
        this.subscriptions.push(getHoldingsFailureStatus);

        this.subscriptions.push(this.proposalAccountService.isPortfolioCallInProgress.subscribe((isCallInProgress) => {
            isCallInProgress ? this.spinnerService.start() : this.spinnerService.stop();
        }));
    }

    public selectExistingAccountsClicked(): void {
        this.router.navigate([`/Proposal/WIP/${this.proposal?.id}/Scenarios/${this.scenario.id}/ExistingAccounts`]);
    }

    public addProposedAccountsClicked(): void {
        this.proposalAccountService.addNewAccountToProposal(this.proposal);
    }

    public isProposedAccountsSectionVisible(): boolean {
        return this.proposal?.scenarios[0]?.accounts
            ?.filter((account: Account) => !account.currentAccountId).length > 0;
    }

    public isProposedExistingAccountsSectionVisible(): boolean {
        return this.selectedExistingAccounts.length > 0;
    }

    public isMaximumNumberOfAccountsReached(): boolean {
        return this.proposalAccountService
            .checkIfBelowMaximumNumOfAccounts(this.proposal?.scenarios[0]?.accounts, true);
    }

    public showSelectExistingAccountsButton(): boolean {
        return this.isExistingAccountsFeatureEnabled &&
            this.proposal.clients.some((client: Client) => client.entityType === EntityType.Client && Number(client.entityId) !== 9999);
    }

    private setExistingAccountsFromProposal(): void {
        const proposalAccounts: Account[] = this.scenario?.accounts;
        if (!proposalAccounts.length) {
            this.selectedExistingAccounts = [];
        } else {
            proposalAccounts.forEach((account: Account) => {
                if (account?.currentAccountId &&
                    !this.selectedExistingAccounts.some((existingAccount: ExistingAccountData) =>
                        Number(existingAccount?.accountId) === Number(account?.currentAccountId))) {
                    const clientExistingAccountList: ExistingAccountData[] =
                        this.existingAccounts.get(Number(account?.owners[0]?.entityId));
                    const currentAccountSnapshot = clientExistingAccountList?.find((existingAccount: ExistingAccountData) =>
                        Number(account?.currentAccountId) === Number(existingAccount?.accountId));
                    this.selectedExistingAccounts.push(currentAccountSnapshot);
                }
            });
        }
    }

    private removeAnyUnsavedAccounts(proposalAccounts: Account[]): Account[] {
        return proposalAccounts?.filter((account) => !account?.isNewAccount);
    }

}
