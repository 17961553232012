/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BannerMessageType, DisplayAmount, DisplayMonetaryAmountPipe } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { GoalOptionTypeId } from '../../model/enums';
import { AchievabilityAnalysisRequest, AchievabilityAnalysisResponse, AchievabilityAnalysisResponseAttributes } from '../../model/proposal';
import { GoalService } from '../../service/goal.service';

@Component({
    selector: 'sei-car-monte-carlo-analysis-drawer',
    templateUrl: './monte-carlo-analysis-drawer.html',
    styleUrls: ['./monte-carlo-analysis-drawer.scss']
})
export class MonteCarloAnalysisDrawerComponent extends ParentComponentSubscriptionManager {

    @Input()
    set achievabilityAnalysisRequest(value: AchievabilityAnalysisRequest) {
        if (value) {
            this._achievabilityAnalysisRequest = value;
            this.isLoading = true;
            this.isMonteCarloRequestLoading.emit(this.isLoading);
            this.analyticsData = undefined;
            this.sendRequest();
        }
    }

    get achievabilityAnalysisRequest(): AchievabilityAnalysisRequest {
        return this._achievabilityAnalysisRequest;
    }

    @Input()
    public displayWarningMessage: boolean;

    @Output()
    public isMonteCarloRequestLoading: EventEmitter<boolean> = new EventEmitter();

    @Output()
    public isMonteCarloRequestError: EventEmitter<boolean> = new EventEmitter();

    public isLoading: boolean = false;
    public analyticsData: AchievabilityAnalysisResponseAttributes;
    public warningType: BannerMessageType = BannerMessageType.warning;

    private _achievabilityAnalysisRequest: AchievabilityAnalysisRequest;

    constructor(
        private goalService: GoalService,
        private monetaryAmountPipe: DisplayMonetaryAmountPipe
    ) {
        super('MonteCarloAnalysisDrawer');
    }

    public getTargetDateYear(): number {
        return new Date(this.achievabilityAnalysisRequest?.goalTargetDate).getFullYear();
    }

    public getGoalProbability(): string {
        const probabilityOfSuccess: number = Number(this.analyticsData?.analyticsDetails?.probabilityOfSuccess);
        if (probabilityOfSuccess > 99) {
            return '> 99';
        } else if (probabilityOfSuccess < 0.1) {
            return '< 0.1';
        }
        return probabilityOfSuccess.toFixed(2);
    }

    public getExpectedReturn(): string {
        return (Number(this.analyticsData?.forecastDetails?.at(0)?.expectedReturn) * 100).toFixed(2);
    }

    public getStandardDeviation(): string {
        return (Number(this.analyticsData?.forecastDetails?.at(0)?.expectedRisk) * 100).toFixed(2);
    }

    public getTargetAmount(): string {
        return this.formatMonetaryAmount(Number(this.analyticsData?.targetData?.at(0)?.amount));
    }

    private formatMonetaryAmount(amount: number): string {
        const displayAmount: DisplayAmount = {
            value: amount,
            actualCurrency: 'USD'
        };
        return this.monetaryAmountPipe.transform(displayAmount, undefined, true);
    }

    public leftAlignTargetAmount(): boolean {
        return this.achievabilityAnalysisRequest?.goalType === GoalOptionTypeId.MajorPurchase ||
            this.achievabilityAnalysisRequest?.goalType === GoalOptionTypeId.SecondHome ||
            this.achievabilityAnalysisRequest?.goalType === GoalOptionTypeId.WealthTransfer;
    }

    private sendRequest(): void {
        this.subscriptions.push(
            this.goalService.retrieveMonteCarloAnalysis(this.achievabilityAnalysisRequest)
                .subscribe({
                    next: (response: AchievabilityAnalysisResponse) => {
                        this.analyticsData = response.data.attributes;
                    },
                    error: (error) => {
                        this.isLoading = false;
                        this.isMonteCarloRequestLoading.emit(this.isLoading);
                    },
                    complete: () => {
                        this.isLoading = false;
                        this.isMonteCarloRequestLoading.emit(this.isLoading);
                        this.isMonteCarloRequestError.emit(false);
                    }
                })
        );
    }

}
