<div class="row">
    <div class="col-xs-12">
        <h1>Goals</h1>
        <h2>{{ scenario.name }}</h2>
    </div>
</div>
<div class="row sei-margin-top add-goal-margin-bottom"
     *ngIf="isMonteCarloFeatureEnabled">
    <div class="col-xs-12">
        <sei-button [buttonDisabled]="!isAddGoalEnabled || this.scenario?.goals?.length > 9"
                    class="btn-in-tooltip"
                    buttonText="Add Goal"
                    buttonClass="btn-primary"
                    (buttonClicked)="addAdditionalGoal()">
        </sei-button>
    </div>
</div>
<div class="row sei-margin-top">
    <div class="col-xs-12">
        <sei-car-edit-goal-ribbon *ngFor="let goal of scenario.goals; let i = index"
                                  [goal]="goal"
                                  [goalCatalogs]="goalCatalogs"
                                  [scenario]="scenario"
                                  [index]="getIndexForGoalName(i)"
                                  [proposalId]="proposal.id"
                                  (removeGoal)="onRemoveGoal($event)"
                                  (isFormValid)="onIsFormValid($event)">
        </sei-car-edit-goal-ribbon>
    </div>
</div>
<div class="row sei-margin-top"
     *ngIf="!isMonteCarloFeatureEnabled">
    <div class="col-xs-12">
        <sei-button [buttonDisabled]="!isAddGoalEnabled || this.scenario?.goals?.length > 9"
                    class="btn-in-tooltip"
                    buttonText="Add Goal"
                    buttonClass="btn-primary"
                    (buttonClicked)="addAdditionalGoal()">
        </sei-button>
    </div>
</div>
